<template>
  <div class="animated fadeIn">
    <div v-if="loadingOrder" class="row justify-content-center">
      <div class="col mt-3 mb-3">
        <loading-animation />
      </div>
    </div>

    <div v-else-if="!order" class="text-center">
      <p>Pedido não encontrado</p>
    </div>

    <div v-else-if="!order.stockLocale" class="text-center">
      <p>Esse pedido não possui armazém</p>
    </div>

    <div v-else-if="!order.orderItems || !order.orderItems.length" class="text-center">
      <p>Esse pedido não possui itens</p>
    </div>

    <div v-else>
      <div class="row justify-content-between mb-3">
        <div class="col-auto">
          <h4 class="ml-md-0 ml-3">Documento {{order.number}}</h4>
        </div>
        <div v-if="!isComplete" class="col-md col-12">
          <div class="row justify-content-md-end justify-content-sm-between justify-content-end">

            <div class="col-sm-auto col-12">
              <b-button variant="primary" @click="openAllocationSuggestionsModal()" class="ml-md-0 ml-3 mobile-btn-90">
                Buscar sugestões de alocação
              </b-button>
            </div>

            <div class="col-sm-auto col-12">
              <b-button variant="secondary" @click="openBarcodesPrintModal()" class="ml-md-0 ml-3 mobile-btn-90">
                Imprimir Códigos de Barra dos Lotes
              </b-button>
            </div>

            <div v-if="order && (order.productionMS || (order.transformationMS && order.transformationMS.system === 'C'))" class="col-auto pr-3">
              <b-button variant="warning" @click="openOrderFinishModal" class="mr-md-0 ml-sm-0 ml-3 mt-sm-0 mt-3"
                :disabled="!order.orderItems || !order.orderItems.length">
                Finalizar pedido
              </b-button>
            </div>

          </div>
        </div>
      </div>

      <b-row class="mx-md-n3 mx-1">
        <b-col v-if="isComplete">
          <b-col>
            <h1>Alocação finalizada!!</h1>
            <p>Você sera redirecionado em alguns segundos... </p>
          </b-col>
        </b-col>

        <div v-else-if="loadingItemLots || savingOrder" class="mb-5 pb-5">
          <loading-animation />
        </div>

        <div v-else class="col-12">
          <div class="row">
            <div
              class="col-12 col-md-6 col-xl-4 px-2"
              v-bind:class="{
                'col-sm-6': orderItem.item.images.length,
                'col-sm-12': !orderItem.item.images.length
              }"
              @click.prevent="showLotSuggestions(orderItem)"
              @contextmenu="openOrderItemContextMenu($event, orderItem, index)"
              ref='box'
              v-for="(orderItem, index) in order.orderItems"
              :key="orderItem.id"
              :id="orderItem.id"
            >
              <div class="card pointer" :ref="orderItem.id" :class="orderItem.alocatedQuantity >= orderItem.quantity ? 'text-white bg-success' :
                orderItem.anyOrderItemLotsWithoutQuantityPerVolume ? 'text-white bg-warning' :
                orderItem.areLotsConfirmed ? 'text-white bg-info' : orderItem.doesNotHaveAnyTransferExitOrderItemLot ? 'text-white bg-danger' : ''">
                <div class="card-body mx-md-0 mx-3 px-2">
                  <div class="row mx-0">
                    <div class="col pl-0 pr-2 img-fix-size" v-if="orderItem && orderItem.item && orderItem.item.images.length">
                      <img :src="orderItem.item.images[0].url" width="100%">
                    </div>
                    <div class="col px-0">
                      <div v-if="orderItem.alocatedQuantity >= orderItem.quantity" class="h1 text-muted text-right mb-4">
                        <i class="fa fa-check"></i>
                      </div>
                      <div class="h5 mb-0">{{orderItem.item.description}}</div>
                      <p class="text-muted mb-1">{{ getItemReduzidoFromRef(orderItem.item.ref) }}</p>
                      <small v-if="orderItem.alocatedQuantity < orderItem.quantity" class="text-muted text-uppercase font-weight-bold">
                        Pendentes: {{ subtract(orderItem.quantity, orderItem.alocatedQuantity) }}
                      </small>
                    </div>
                  </div>
                  <b-progress
                        v-if="orderItem.alocatedQuantity < orderItem.quantity"
                        height={}
                        class="progress-xs mt-3 mb-0"
                        variant="info"
                        :value="(orderItem.alocatedQuantity / orderItem.quantity) * 100"/>
                </div>
              </div>
            </div>
            <div v-if="order && order.transformationMS && order.transformationMS.system === 'C'"
              @click="openOrderItemEditModal(null)"
              class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
              <div class="card pointer">
                <div class="card-body text-center py-5 text-muted">
                  <i class="fa fa-plus fa-4x my-1"></i><br>
                  <h5 class="font-weight-bold">Adicionar item</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <b-modal
          size="lg"
          centered
          lazy
          :title="modalTitle"
          header-bg-variant="info"
          footer-border-variant="info"
          @hidden="onHidden"
          v-model="modalShow"
          id="movements-modal"
          :hide-footer="true"
        >
          <b-container class="pl-sm-3 pl-0">
            <div class="row">
              <div class="col-lg-6 col-12">
                <div class="row">
                  <div class="col-10">
                    <model-list-select
                      v-if="this.selectItem.locals"
                      :list="this.selectItem.locals"
                      option-value="id"
                      option-text="name"
                      v-model="selectedLocal"
                      placeholder="Selecione o Endereço"
                      @searchchange="searchAllocation"
                      :filterPredicate="alwaysTrueFilterPredicate"/>
                  </div>
                  <div v-if="loadingAllocations" class="col-2 mb-lg-0 mb-3">
                    <loading-animation />
                  </div>
                  <div v-else class="col-2 mb-lg-0 mb-3">
                    <b-button variant="secondary" @click.prevent="openLocationBarcodeInput">
                      <i class="fa fa-barcode"></i>
                    </b-button>
                  </div>

                  <div v-if="!wereItemLotsAutomaticallyCreated && !isSelectItemItemLotAutomatic" class="col-12">
                    <div class="row">
                      <div class="col-10 mt-lg-3 mt-0">
                        <model-list-select
                          v-if="this.selectItem.lotsAvailable"
                          :list="this.selectItem.lotsAvailable"
                          option-value="id"
                          option-text="lotNumber"
                          v-model="selectedLot"
                          placeholder="Selecione o Lote"
                          @searchchange="searchLot"
                          :filterPredicate="alwaysTrueFilterPredicate"/>
                        <div v-if="!disableLotCreation && !order.transfer" class="row justify-content-center mt-3">
                          <div class="col-auto">
                            <b-button variant="success" @click.prevent="openLotCreationForm(false)">
                              <i class="fa fa-plus mr-1"></i> Criar Novo Lote
                            </b-button>
                          </div>
                        </div>
                      </div>
                      <div v-if="loadingLots" class="col-2 mb-lg-0 mb-3 mt-lg-3 mt-0">
                        <loading-animation />
                      </div>
                      <div v-else class="col-2 mb-lg-0 mb-3 mt-lg-3 mt-0">
                        <b-button variant="secondary" @click.prevent="openLotBarcodeInput">
                          <i class="fa fa-barcode"></i>
                        </b-button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-6 col-12 mt-lg-0 mt-3">
                <div class="row">
                  <div v-if="savingMovement || loadingBalances" class="col-10">
                    <loading-animation />
                  </div>
                  <div v-if="!savingMovement && !loadingBalances && localsAndLotSelected" class="col-auto col-12 pr-0 pb-2">
                    <label for="quantity" class="mb-0 margin-right pb-0">Quantidade a ser inserida:</label>
                  </div>
                  <div class="row align-items-center mx-0">
                    <div v-if="!savingMovement && !loadingBalances && localsAndLotSelected && selectedLot.quantityPerVolume" class="col">
                      <b-input v-model="manualSelectionNumberOfVolumes" id="manualSelectionNumberOfVolumes" onFocus="this.setSelectionRange(0, this.value.length)"></b-input>
                    </div>
                    <div v-if="!savingMovement && !loadingBalances && localsAndLotSelected && selectedLot.quantityPerVolume" class="col-auto px-0">
                      <span class="text-muted">x </span>
                      {{ selectedLot.quantityPerVolume }} =
                    </div>
                    <div v-if="!savingMovement && !loadingBalances && localsAndLotSelected" class="col">
                      <b-input v-model="quantity" id="quantity" onFocus="this.setSelectionRange(0, this.value.length)"></b-input>
                    </div>
                    <div v-if="!savingMovement && !loadingBalances && localsAndLotSelected" class="col-2">
                      <b-button class="float-right" variant="primary" @click.prevent="addItem" :disabled="quantity <= 0">
                        <i class="fa fa-check"></i>
                      </b-button>
                    </div>
                  </div>
                  <div class="non-mobile-hide col-sm-12 col-10 animated fadeIn mt-lg-3 mt-sm-1 mt-3">
                    <label v-if="!loadingBalances && localsAndLotSelected" class="mt-2 w-100">
                      Quantidade Atual: {{currentLocationQuantity}}
                    </label>
                  </div>
                  <div class="mobile-hide col-sm-12 col-10 animated fadeIn mt-lg-3 mt-sm-1 mt-3">
                    <label v-if="!loadingBalances && localsAndLotSelected" class="mt-2 w-100">
                      Quantidade Atual: {{currentLocationQuantity}}
                    </label>
                  </div>
                </div>
                <div v-if="!isSelectItemItemLotAutomatic && !wereItemLotsAutomaticallyCreated && !savingMovement && localsAndLotSelected"
                  class="row justify-content-center mt-3">
                  <div class="col-auto">
                    <b-button variant="secondary" @click.prevent="openSelectedLotPrintModal">
                      <i class="fa mr-1"></i> Imprimir Códigos de Barra do Lote Selecionado
                    </b-button>
                  </div>
                </div>
              </div>
            </div>
          </b-container>
        </b-modal>

        <b-modal
          size="lg"
          centered
          lazy
          :title="'Preencher quantidade por volume - ' + modalTitle"
          header-bg-variant="info"
          footer-border-variant="info"
          @hidden="onHidden"
          v-model="showQuantityPerVolumeModal"
          id="quantity-per-volume-modal"
          :hide-footer="true"
        >
          <div v-if="loadingItemLots || creatingItemLotSuggestions" class="row justify-content-center">
            <div class="col mt-3 mb-3">
              <loading-animation />
            </div>
          </div>

          <b-container v-else>
            <b-button variant="primary" class="mobile-btn-100 small-btn-100 medium-btn-100 mb-3"
              @click.prevent="openQuantityPerVolumeItemLotBarcodeInputModal" :disabled="loadingQuantityPerVolumeBarcode">
              <i class="fa fa-barcode"></i> Encontrar lote pelo código de barras
            </b-button>

            <div v-for="(oil, index) in selectedItemOrderItemLots" class="row py-3 border mx-0 align-items-center"
              :key="index" :class="{ 'bg-light': index % 2 != 0 }">

              <div class="col-lg col-sm-6 col-12 mb-sm-0 mb-1">
                <span class="font-weight-bold">Número: </span>
                {{oil.itemLot ? oil.itemLot.lotNumber : '' }}
              </div>
              <div class="col-lg col-sm-6 col-12 mb-sm-0 mb-1">
                <span class="font-weight-bold">Quantidade: </span>
                {{oil.quantity}}
              </div>
              <div v-if="oil.itemLot" class="col-sm-auto col-12 pr-0 mt-sm-1 mt-lg-0 mt-0">
                <span class="font-weight-bold">Qtd. por Volume: </span>
              </div>
              <div v-if="oil.itemLot" class="col-sm col-12 mt-sm-1 mt-lg-0 mt-0">
                <b-input v-model="oil.itemLot.quantityPerVolume" :id="'OrderItemQuantityPerVolume-' + index"
                  onFocus="this.setSelectionRange(0, this.value.length)" :autofocus="index === 0"
                  @keyup.enter="quantityPerVolumeFillInputEnterPressed"
                  :ref="'quantityPerVolumeFillInput-' + index"
                ></b-input>
              </div>

            </div>

            <div class="row justify-content-end mt-3">
              <div class="col-sm-auto col-12">
                <b-button variant="primary" @click.prevent="saveSelectedItemOrderItemLotsQuantityPerVolume" class="mobile-btn-100">
                  Salvar
                </b-button>
              </div>
            </div>
          </b-container>
        </b-modal>

        <b-modal
          size="md"
          centered
          lazy
          :title="'Criar Novo Lote - ' + modalTitle"
          header-bg-variant="info"
          footer-border-variant="info"
          v-model="showItemLotCreatingForm"
          id="itemLot-creation-form-modal"
          :hide-footer="true"
        >
          <b-container class="pl-sm-3 pl-0">
            <b-form-group
              id="new-itemLot-number"
              label="Número"
              label-for="new-itemLot-number-input"
              :invalid-feedback="newItemLotNumberInvalidFeedback"
            >
              <b-form-input
                :required="!newItemLot.createLotNumberAutomatically"
                :disabled="newItemLot.createLotNumberAutomatically"
                id="new-itemLot-number-input"
                type="text"
                v-model="newItemLot.lotNumber"/>

                <b-form-checkbox v-model="newItemLot.createLotNumberAutomatically" class="mt-2">
                  <span>Gerar Número do Lote Automaticamente</span>
                </b-form-checkbox>
            </b-form-group>

            <b-form-group
              id="new-itemLot-barcode"
              label="Código de Barras"
              label-for="new-itemLot-barcode-input"
              :invalid-feedback="newItemLotBarcodeInvalidFeedback"
            >
              <b-form-input
                :required="!newItemLot.createBarcodeAutomatically"
                :disabled="newItemLot.createBarcodeAutomatically"
                id="new-itemLot-barcode-input"
                type="text"
                v-model="newItemLot.barcode"
                @keydown.enter="$event.target.blur()"/>

              <b-form-checkbox v-model="newItemLot.createBarcodeAutomatically" class="mt-2">
                <span>Gerar Código de Barras do Lote Automaticamente</span>
              </b-form-checkbox>
            </b-form-group>

            <div v-if="wasItemLotCreationCalledFromSuggestion" class="row align-items-end mb-3">
              <div class="col">
                <b-form-group
                  id="new-itemLot-quantity"
                  label="Quantidade por Volume"
                  :invalid-feedback="newItemLotQuantityPerVolumeInvalidFeedback"
                  class="mb-0"
                >
                  <b-form-input
                    :required="true"
                    ref="newItemLotQuantityPerVolumeInput"
                    type="text"
                    @blur="updateNewItemLotQuantity"
                    @keyup.enter="createItemLot"
                    v-model="newItemLot.quantityPerVolume"/>
                </b-form-group>
              </div>
              <div class="col-auto px-0 mb-2">x</div>
              <div class="col pr-2">
                <b-form-group
                  id="new-itemLot-quantity"
                  label="Número de Volumes"
                  :invalid-feedback="newItemLotNofVolumesInvalidFeedback"
                  class="mb-0"
                >
                  <b-form-input
                    :required="true"
                    type="text"
                    @blur="updateNewItemLotQuantity"
                    @keyup.enter="createItemLot"
                    v-model="newItemLot.tempNofVolumes"/>
                </b-form-group>
              </div>
              <div class="col-auto pl-0 mb-2">
                = <span class="h5 font-weight-bold">{{ newItemLot.quantity }}</span>
              </div>
            </div>

            <div class="row justify-content-end">
              <div class="col-auto pr-0">
                <b-button v-if="!creatingNewItemLot" type="submit" variant="success" @click="createItemLot">
                  <i class="fa fa-plus"></i> Criar
                </b-button>
                <div v-if="creatingNewItemLot" class="col-auto">
                  <loading-animation/>
                </div>
              </div>
            </div>
          </b-container>
        </b-modal>
      </b-row>

      <barcode-input-modal
        id="location-barcode-input-modal"
        :attempts="locationBarcodeAttempts"
        :loading="loadingLocationBarcode"
        :ok="readLocationBarcode"
        :config="config"
        :authUser="authUser"
        :parameter="parameter"
      ></barcode-input-modal>
      <barcode-input-modal
        id="lot-barcode-input-modal"
        :attempts="lotBarcodeAttempts"
        :loading="loadingLotBarcode"
        :ok="readLotBarcode"
        :config="config"
        :authUser="authUser"
        :parameter="parameter"
      ></barcode-input-modal>

      <b-modal
        id="barcodes-print-modal"
        centered
        size="xl"
        lazy
        title="Imprimir códigos de barra"
        header-bg-variant="info"
        footer-border-variant="info"
        v-model="showBarcodesPrintModal">
        <b-container>
          <div v-if="loadingBarcodePDF">
            <loading-animation />
          </div>

          <div v-if="!loadingBarcodePDF && (!orderItemLots || !orderItemLots.length)">
            <div class="row bg-light mx-0 py-3">
              <div class="col text-center">
                Nenhum Lote encontrado
              </div>
            </div>
          </div>

          <div v-if="!loadingBarcodePDF && orderItemLots && orderItemLots.length">
            <div v-for="(printOrderItemLot, index) in orderItemLots" class="row py-3 border mx-0 align-items-center" :key="index"
                :class="{ 'bg-light': index % 2 != 0 }">
              <div class="col-sm col-12">
                <div class="row">
                  <div class="col-12">
                    <span class="font-weight-bold">Item: </span>
                    {{printOrderItemLot.orderItem && printOrderItemLot.orderItem.item ? printOrderItemLot.orderItem.item.description : '' }}
                  </div>
                  <div class="col-12">
                    <span class="font-weight-bold">Quantidade: </span>
                    <span v-if="printOrderItemLot.itemLot">
                      {{ printOrderItemLot.quantity / printOrderItemLot.itemLot.quantityPerVolume }}
                      <small>
                        <span class="text-muted">x </span>
                        {{ printOrderItemLot.itemLot.quantityPerVolume }}
                      </small>
                      =
                    </span>
                    <span class="h5 font-weight-bold">{{ printOrderItemLot.quantity }}</span>
                  </div>
                  <div class="col-12">
                    <span class="font-weight-bold">Número do Lote: </span>
                    {{printOrderItemLot.itemLot ? printOrderItemLot.itemLot.lotNumber : '' }}
                  </div>
                </div>
              </div>
              <div class="col-sm-auto col-12 mt-sm-0 mt-3">
                <div class="row justify-content-end align-items-center">
                  <h4 v-if="!loadingBarcodePDF && printOrderItemLot.printed" class="col-auto mb-0 pr-0">
                    <i class="cui-check text-success"></i>
                  </h4>
                  <div class="col-auto">
                    <div v-if="loadingBarcodePDF" class="mr-5">
                      <loading-animation />
                    </div>
                    <b-button v-else :variant="printOrderItemLot.printed ? 'secondary' : 'primary'"
                      @click="printOrderItemLotsBarcodes(printBarcodes, orderItemLots, index)"
                      :disabled="!barcodePrintModel || !barcodePrintModel.label"
                      class="mobile-btn-100">
                      Gerar PDF
                    </b-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-container>

        <template slot="modal-footer">
          <div class="row w-100 justify-content-between mx-0">
            <div class="col-xl-2 col-lg-3 col-md-4 col-sm-5 col-12 mb-sm-0 mb-3">
              <model-list-select
                :list="barcodePrintModels"
                option-value="value"
                option-text="label"
                v-model="barcodePrintModel"
              />
            </div>
            <div class="col-sm-auto col-12">
              <b-button variant="secondary" @click="closeBarcodePrintModal" class="mobile-btn-100">Cancelar</b-button>
            </div>
          </div>
        </template>
      </b-modal>

      <vue-confirm-dialog></vue-confirm-dialog>

      <b-modal
        id="selected-lot-barcodes-print-modal"
        centered
        size="sm"
        lazy
        title="Imprimir Códigos de Barra do Lote Selecionado"
        header-bg-variant="info"
        footer-border-variant="info"
        v-model="showSelectedLotBarcodesModal">
        <b-container>
          <b-form-group
            label="Modelo de etiqueta"
          >
            <model-list-select
              :list="barcodePrintModels"
              option-value="value"
              option-text="label"
              v-model="barcodePrintModel"
            />
          </b-form-group>
        </b-container>

        <template slot="modal-footer" slot-scope="{ ok, cancel }">
          <b-button variant="secondary" @click="closeSelectedLotBarcodePrintModal">Cancelar</b-button>
          <b-button variant="primary" @click="printOrderItemLotsBarcodes(printSelectedLotBarcodes, orderItemLots)"
            :disabled="!barcodePrintModel || !barcodePrintModel.label">
            Gerar PDF
          </b-button>
        </template>
      </b-modal>

      <b-modal
        size="md"
        centered
        lazy
        :title="'Criar Lotes - ' + modalTitle"
        header-bg-variant="info"
        footer-border-variant="info"
        v-model="showItemLotListModal"
        id="item-lot-list-modal"
        :hide-footer="true"
      >
        <div v-if="loadingItemLots || creatingItemLotSuggestions || loadingBarcodePDF" class="row justify-content-center">
          <div class="col mt-3 mb-3">
            <loading-animation />
          </div>
        </div>

        <b-container v-else>
          <b-row class="justify-content-between mb-3 align-items-end">
            <div class="col-sm-6 col-12 pl-0 pr-sm-3 pr-0">
              <b-form-group
                label="Quantidade por Volume"
                label-for="quantityPerVolume"
                class="mb-0"
              >
                <b-form-input
                  id="quantityPerVolume"
                  ref="itemLotCCreationListQuantityPerVolumeInput"
                  v-model="quantityPerVolume"
                  onFocus="this.setSelectionRange(0, this.value.length)"
                  @keyup.enter="getLotSuggestions"
                />
              </b-form-group>
            </div>
            <div class="col-sm-6 col-12 pr-0 pl-sm-3 pl-0 mt-sm-0 mt-3">
              <b-form-group
                label="Prefixo"
                label-for="itemLotSuggestionPrefix"
                class="mb-0"
              >
                <b-form-input
                  id="itemLotSuggestionPrefix"
                  v-model="itemLotSuggestionPrefix"
                  @keyup.enter="getLotSuggestions"
                />
              </b-form-group>
            </div>
            <div class="col-sm col-12 mt-3 pl-0 pr-sm-3 pr-0">
              <b-button variant="primary" @click.prevent="getLotSuggestions" :disabled="!itemLotSuggestionPrefix" class="mobile-btn-100">
                Gerar sugestões
              </b-button>
            </div>
            <div class="col-sm-auto col-12 mt-sm-3 mt-3 pr-0 pl-sm-3 pl-0">
              <b-button variant="secondary" @click.prevent="onShow()" class="mobile-btn-100">
                Selecionar Manualmente
              </b-button>
            </div>
          </b-row>

          <div v-if="!lotSuggestions || !lotSuggestions.length" class="mb-3">
            <div class="row bg-light mx-0 py-3">
              <div class="col text-center">
                Nenhum lote encontrado
              </div>
            </div>
          </div>

          <div v-else class="mb-3 mx-n3">
            <div v-for="(lotSuggestion, index) in lotSuggestions" class="row py-3 border mx-0 align-items-center"
              :key="index" :class="{ 'bg-light': index % 2 != 0 }">
              <div class="col-sm col-12 mb-sm-0 mb-1">
                <span class="font-weight-bold">Número: </span>
                {{lotSuggestion.itemLot ? lotSuggestion.itemLot.lotNumber : '' }}
              </div>
              <div class="col">
                <div class="row align-items-center">
                  <div class="col pr-1">
                    <b-input v-model="lotSuggestion.numberOfVolumes" :id="'LotSuggestionQuantity-' + index"
                      @focus="editingSuggestionQuantity = true" class="text-right"
                      onFocus="this.setSelectionRange(0, this.value.length)"
                      @blur="setLotSuggestionManualQuantity(index)"></b-input>
                  </div>
                  <div class="col-auto pl-0">
                    <p v-if="lotSuggestion.itemLot" class="mb-0">
                      <small>
                        <span class="text-muted">x </span>
                        {{ lotSuggestion.itemLot.quantityPerVolume }}
                      </small>
                      = <span class="h5 font-weight-bold">{{ lotSuggestion.quantity }}</span>
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-auto">
                <b-button variant="danger" @click.prevent="openDeleteModal(index)">
                  <i class="fa fa-trash"></i>
                </b-button>
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-auto">
                <b-button variant="success" @click.prevent="openLotCreationForm(true)" class="mt-3">
                  <i class="fa fa-plus mr-1"></i> Adicionar Novo Lote
                </b-button>
              </div>
            </div>
          </div>

          <div class="row justify-content-between align-items-center">
            <div class="col-sm-auto col-12 pl-0">
              <b-form-checkbox v-model="printCreatedLotsBarcodes">
                <span class="font-weight-bold">Imprimir códigos de Barra</span>
              </b-form-checkbox>
            </div>
            <div class="col-sm-auto col-12 pr-0 pl-sm-3 pl-0">
              <div class="row mb-2">
                <div class="col mt-sm-0 mt-2">
                  <small v-if="isAnyLotToBeCreatedZero" class="text-danger font-weight-bold">
                    Existem Lotes com quantidade 0. <br> Remova-os ou altere sua quantidade.
                  </small>
                  <span v-else-if="lotsToBeCreatedSum !== subtract(selectItem.quantity, selectItem.alocatedQuantity)"
                    class="text-danger font-weight-bold">
                    Quantidade calculada: {{lotsToBeCreatedSum}}
                  </span>
                </div>
              </div>

              <div class="row justify-content-end">
                <div class="col-sm-auto col-12">
                  <b-button variant="primary" @click.prevent="confirmLotSuggestionsCreation" class="mobile-btn-100"
                    :disabled="lotsToBeCreatedSum !== subtract(selectItem.quantity, selectItem.alocatedQuantity) || isAnyLotToBeCreatedZero || editingSuggestionQuantity">
                    <i class="fa fa-check"></i> Confirmar
                  </b-button>
                </div>
              </div>
            </div>
          </div>
        </b-container>
      </b-modal>

      <b-modal
        id="select-item-barcodes-print-modal"
        centered
        size="xl"
        lazy
        :title="'Imprimir códigos de barra do item ' + selectItemLabel"
        header-bg-variant="info"
        footer-border-variant="info"
        v-model="showSelectItemBarcodesPrintModal"
        :no-close-on-backdrop="true">
        <b-container>
          <div v-if="loadingBarcodePDF">
            <loading-animation />
          </div>

          <div v-if="!loadingBarcodePDF && (!selectedItemOrderItemLots || !selectedItemOrderItemLots.length)">
            <div class="row bg-light mx-0 py-3">
              <div class="col text-center">
                Nenhum Lote encontrado
              </div>
            </div>
          </div>

          <div v-if="!loadingBarcodePDF && selectedItemOrderItemLots && selectedItemOrderItemLots.length">
            <div v-for="(printOrderItemLot, index) in selectedItemOrderItemLots" class="row py-3 border mx-0 align-items-center" :key="index"
                :class="{ 'bg-light': index % 2 != 0 }">
              <div class="col-sm col-12">
                <div class="row">
                  <div class="col-12">
                    <span class="font-weight-bold">Quantidade: </span>
                    <span v-if="printOrderItemLot.itemLot">
                      {{ printOrderItemLot.quantity / printOrderItemLot.itemLot.quantityPerVolume }}
                      <small>
                        <span class="text-muted">x </span>
                        {{ printOrderItemLot.itemLot.quantityPerVolume }}
                      </small>
                      =
                    </span>
                    <span class="h5 font-weight-bold">{{ printOrderItemLot.quantity }}</span>
                  </div>
                  <div class="col-12">
                    <span class="font-weight-bold">Número do Lote: </span>
                    {{printOrderItemLot.itemLot ? printOrderItemLot.itemLot.lotNumber : '' }}
                  </div>
                </div>
              </div>
              <div class="col-sm-auto col-12 mt-sm-0 mt-3">
                <div class="row justify-content-end align-items-center">
                  <h4 v-if="!loadingBarcodePDF && printOrderItemLot.printed" class="col-auto mb-0 pr-0">
                    <i class="cui-check text-success"></i>
                  </h4>
                  <div class="col-auto">
                    <div v-if="loadingBarcodePDF" class="mr-5">
                      <loading-animation />
                    </div>
                    <b-button v-else :variant="printOrderItemLot.printed ? 'secondary' : 'primary'"
                      @click="printOrderItemLotsBarcodes(printBarcodes, selectedItemOrderItemLots, index)"
                      :disabled="!barcodePrintModel || !barcodePrintModel.label"
                      class="mobile-btn-100">
                      Gerar PDF
                    </b-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-container>

        <template slot="modal-footer">
          <div class="row w-100 justify-content-between mx-0">
            <div class="col-xl-2 col-lg-3 col-md-4 col-sm-5 col-12 mb-sm-0 mb-3">
              <model-list-select
                :list="barcodePrintModels"
                option-value="value"
                option-text="label"
                v-model="barcodePrintModel"
              />
            </div>
            <div class="col-sm-auto col-12">
              <b-button variant="secondary" @click="closeSelectItemBarcodePrintModal" class="mobile-btn-100">Cancelar</b-button>
            </div>
          </div>
        </template>
      </b-modal>

      <b-modal
        size="xl"
        centered
        lazy
        :title="'Executar movimentos dos Lotes criados - ' + modalTitle"
        header-bg-variant="info"
        footer-border-variant="info"
        @hidden="onHidden"
        v-model="showlotSuggestionsMovementsModal"
        id="item-lot-suggestion-movement-modal"
        :hide-footer="true"
      >
        <div v-if="loadingItemLots" class="row justify-content-center">
          <div class="col mt-3 mb-3">
            <loading-animation />
          </div>
        </div>

        <b-container v-else>
          <b-row class="justify-content-between mb-3 align-items-center">
            <div class="col-lg-auto col-12 px-0">
              <b-button v-if="itemLotSuggestionSelectedByBarcodeIndex < 0" variant="primary" class="mobile-btn-100 small-btn-100 medium-btn-100"
                @click.prevent="openItemLotSuggestionLotBarcodeInput" :disabled="savingMovement">
                <i class="fa fa-barcode"></i> Selecionar lote pelo código de barras
              </b-button>
              <b-button v-else variant="danger" class="mobile-btn-100" @click.prevent="resetItemLotSuggestionSelectedByBarcodeIndex"
                :disabled="savingMovement">
                <i class="fa fa-times"></i> Cancelar
              </b-button>
            </div>
            <div class="col-lg-auto col-12 px-0 mt-lg-0 mt-3">
              <b-button variant="secondary" @click.prevent="openAllocateAllItemLotsToTheSameLocationModal"
                :disabled="savingMovement" class="mobile-btn-100 small-btn-100 medium-btn-100">
                Alocar todos os lotes no mesmo endereço
              </b-button>
            </div>
          </b-row>

          <div v-if="!selectedItemOrderItemLots || !selectedItemOrderItemLots.length" class="mb-3">
            <div class="row bg-light mx-0 py-3">
              <div class="col text-center">
                Nenhum lote encontrado
              </div>
            </div>
          </div>

          <div v-else class="mb-3 mx-n3">
            <div v-for="(orderItemLot, index) in selectedItemOrderItemLots" class="row py-3 border mx-0" :key="index"
              :class="{ 'bg-light': index % 2 != 0 && index !== itemLotSuggestionSelectedByBarcodeIndex, 'bg-info': index === itemLotSuggestionSelectedByBarcodeIndex }">
              <div class="col-xl-4 col-md-4 col-sm-4 col-12">
                <span class="font-weight-bold">Número: </span>
                {{orderItemLot.itemLot ? orderItemLot.itemLot.lotNumber : '' }}
              </div>
              <div class="col-xl-3 col-md-8 col-sm-8 col-12">
                <div class="row">
                  <div v-if="orderItemLot.itemLot && !order.transfer" class="col-12">
                    <span class="font-weight-bold">Volumes: </span>
                    {{Math.floor((orderItemLot.alocatedQuantity || 0) / orderItemLot.itemLot.quantityPerVolume)}}/{{orderItemLot.quantity / orderItemLot.itemLot.quantityPerVolume}}
                    <small>
                      <span class="text-muted">x </span>
                      {{ orderItemLot.itemLot.quantityPerVolume }}
                    </small>
                  </div>
                  <div class="col-12">
                    <span class="font-weight-bold">Quantidade: </span>
                    {{orderItemLot.alocatedQuantity || 0}}/{{orderItemLot.quantity}}
                    <small v-if="order.transfer && orderItemLot.itemLot && orderItemLot.itemLot.quantityPerVolume" class="ml-1 text-muted">
                      ({{orderItemLot.itemLot.quantityPerVolume}})
                    </small>
                  </div>
                  <div class="col-12">
                    <b-progress v-if="orderItemLot.alocatedQuantity" height={} class="progress-xs mt-1 mb-2"
                      variant="success" :value="(orderItemLot.alocatedQuantity / (orderItemLot.quantity || 0)) * 100"/>
                  </div>
                  <div v-if="(orderItemLot.alocatedQuantity || 0) < (orderItemLot.quantity || 0)"
                    class="col pl-3">
                    <div class="row align-items-center">
                      <div v-if="orderItemLot.itemLot && orderItemLot.itemLot.quantityPerVolume" class="col">
                        <b-input v-model="orderItemLot.usedNumberOfVolumes" class="mt-1" onFocus="this.setSelectionRange(0, this.value.length)"
                        @blur="setUsedQuantityByUsedNumberOfVolumes(orderItemLot)"></b-input>
                      </div>
                      <div v-if="orderItemLot.itemLot && orderItemLot.itemLot.quantityPerVolume" class="col-auto px-0">
                        <span class="text-muted">x </span>
                        {{ orderItemLot.itemLot.quantityPerVolume }} =
                      </div>
                      <div class="col">
                        <b-input v-model="orderItemLot.usedQuantity" class="mt-1" onFocus="this.setSelectionRange(0, this.value.length)"></b-input>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xl-4 col-md-10 col-sm-10 col-12 mt-xl-0 mt-3">
                <div v-if="(orderItemLot.alocatedQuantity || 0) < (orderItemLot.quantity || 0)" class="row">
                  <div v-if="loadingAllocations" class="col-xl-2 col-lg-1 col-2">
                    <loading-animation />
                  </div>
                  <div v-else class="col-xl-2 col-lg-1 col-2">
                    <b-button variant="secondary" @click.prevent="openItemLotSuggestionLocationBarcodeInput(index)"
                      :disabled="savingMovement">
                      <i class="fa fa-barcode"></i>
                    </b-button>
                  </div>
                  <div class="col-xl-10 col-lg-11 col-10">
                    <model-list-select
                      v-if="selectItem.locals"
                      :list="selectItem.locals"
                      option-value="id"
                      option-text="name"
                      v-model="orderItemLot.location"
                      placeholder="Selecione o Endereço"
                      @searchchange="searchAllocation"
                      :filterPredicate="alwaysTrueFilterPredicate"/>
                  </div>
                </div>
              </div>
              <div class="col-xl-1 col-md-2 col-sm-2 col-12 mt-xl-0 mt-3">
                <div v-if="(orderItemLot.alocatedQuantity || 0) < (orderItemLot.quantity || 0)" class="row justify-content-end">
                  <div class="col-auto">
                    <loading-animation v-if="savingMovement"/>
                    <b-button v-else variant="primary" @click="createItemLotMovementByOrderItemLot(index)"
                      :disabled="!orderItemLot.location">
                      <i class="fa fa-check"></i>
                    </b-button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <b-row class="justify-content-between mb-3 align-items-center mt-lg-0 mt-3">
            <div class="col-sm-auto col-12 pl-0 pr-sm-3 pr-0">
              <loading-animation v-if="loadingSelectedItemOrderItemLotsBalance"/>
              <b-button variant="warning" class="mobile-btn-100" @click.prevent="openLotListModalToRecreateItemLots"
                :disabled="Boolean(order.transfer) || haveSelectedItemOrderItemLotsAtLeastOneBalance || savingMovement || disableLotCreation">
                Recriar Lotes
              </b-button>
            </div>
            <div class="col-sm-auto col-12 pr-0 pl-sm-3 pl-0 pt-sm-0 pt-3">
              <b-button variant="secondary" class="mobile-btn-100" @click.prevent="openSelectItemBarcodesPrintModal">
                Imprimir códigos de barra
              </b-button>
            </div>
          </b-row>

          <b-row>
            <div class="col-sm-auto col-12 px-0">
              <b-button variant="secondary" @click.prevent="onShow()" class="mobile-btn-100">
                Selecionar Manualmente
              </b-button>
            </div>
          </b-row>
        </b-container>
      </b-modal>

      <barcode-input-modal
        title="Insira o código de barras do endereço"
        id="itemLot-suggestion-location-barcode-input-modal"
        :withQuantity="true"
        :isEntrance="true"
        :itemLot="itemLot"
        :initialQuantity="lotSuggestionQuantity"
        :attempts="itemLotSuggestionLocationBarcodeAttempts"
        :loading="loadingItemLotSuggestionLocationBarcode"
        :ok="readItemLotSuggestionLocationBarcode"
        :config="config"
        :authUser="authUser"
        :parameter="parameter"
      ></barcode-input-modal>
      <barcode-input-modal
        title="Insira o código de barras do lote"
        id="itemLot-suggestion-lot-barcode-input-modal"
        :attempts="itemLotSuggestionLotBarcodeAttempts"
        :loading="loadingItemLotSuggestionLotBarcode"
        :ok="readItemLotSuggestionLotBarcode"
        :config="config"
        :authUser="authUser"
        :parameter="parameter"
      ></barcode-input-modal>
      <barcode-input-modal
        title="Insira o código de barras do endereço"
        id="general-location-barcode-input-modal"
        :attempts="generalLocationBarcodeAttempts"
        :loading="loadingGeneralLocationBarcode"
        :ok="readGeneralLocationBarcode"
        :config="config"
        :authUser="authUser"
        :parameter="parameter"
      ></barcode-input-modal>
      <barcode-input-modal
        title="Insira o código de barras do lote"
        id="quantity-per-volume-barcode-input-modal"
        :attempts="quantityPerVolumeBarcodeAttempts"
        :loading="loadingQuantityPerVolumeBarcode"
        :ok="readQuantityPerVolumeBarcode"
        :config="config"
        :authUser="authUser"
        :parameter="parameter"
      ></barcode-input-modal>
      <order-item-form-modal
        :order="order"
        :saved="orderItemSaved"
        ref="orderItemFormModal"
      ></order-item-form-modal>
      <confirmation-modal
        id="order-item-delete-confirmation-modal"
        msg="Tem certeza de que deseja remover esse item?"
        :ok="deleteOrderItem"
      ></confirmation-modal>
    </div>

    <confirmation-modal
      id="lot-suggestion-delete-confirmation-modal"
      msg="Tem certeza de que deseja remover esse lote?"
      :ok="removeLotSuggestion"
    ></confirmation-modal>
    <confirmation-modal
      id="save-quantity-per-volume-confirmation-modal"
      msg="Tem certeza de que deseja salvar as quantidades por volumes inseridas? Após salvar não é possível alterá-las!"
      :ok="confirmSaveSelectedItemOrderItemLotsQuantityPerVolume"
    ></confirmation-modal>

    <b-modal
      size="xl"
      centered
      lazy
      :title="'Finalizar pedido ' + order.number"
      header-bg-variant="info"
      footer-border-variant="info"
      v-model="orderFinishModalShow"
      :hide-footer="true"
      id="order-finish-modal"
    >
      <b-container>
        <div v-if="order.orderItems" class="mb-3 mx-n3">
          <div v-for="(orderItem, index) in order.orderItems" class="row py-3 border mx-0" :key="index"
            :class="{ 'bg-light': index % 2 != 0 }">
            <div class="col-xl-9 col-lg-8 col-12">
              <span  class="font-weight-bold">Item: </span>
              {{orderItem.item ? getItemReduzidoFromRef(orderItem.item.ref) + ' - ' + orderItem.item.description : '' }}
            </div>
            <div class="col-xl-3 col-lg-4 col-12 mt-lg-0 mt-1">
              <div class="row">
                <div class="col">
                  <span class="font-weight-bold">Total: </span>{{orderItem.quantity || 0}}
                </div>
                <div class="col">
                  <span class="font-weight-bold">Alocado: </span>{{orderItem.alocatedQuantity || 0}}
                </div>
                <div class="col" :class="{ 'text-danger': (orderItem.alocatedQuantity || 0) < (orderItem.quantity || 0) }">
                  <span class="font-weight-bold">Pendente: </span>
                  {{(orderItem.quantity || 0) - (orderItem.alocatedQuantity || 0)}}
                </div>
              </div>
            </div>
          </div>
        </div>

        <p class="mx-n3">
          <span v-if="atLeastOneOrderItemUnfinished" class="font-weight-bold text-warning">
            AVISO: Existem itens com quantidade pendente.
          </span><br>
          Você tem certeza de que deseja finalizar o pedido?
        </p>
      </b-container>

      <div class="row justify-content-end">
        <div class="col-auto pr-0">
          <b-button variant="secondary" @click="orderFinishModalShow = false">Não</b-button>
        </div>
        <div class="col-auto">
          <b-button variant="warning" @click="finishOrder" :disabled="savingOrder">Sim</b-button>
        </div>
      </div>
    </b-modal>

    <b-modal
      centered
      lazy
      :title="'Alocar todos os lotes no mesmo endereço - ' + modalTitle"
      header-bg-variant="info"
      footer-border-variant="info"
      v-model="showAllocateAllItemLotsToTheSameLocationModal"
      :hide-footer="true"
      id="allocate-all-item-lots-to-the-same-location-modal"
    >
      <b-container class="px-0">
        <div class="row">
          <div v-if="loadingAllocations" class="col-2 pr-sm-0 pr-3">
            <loading-animation />
          </div>
          <div v-else class="col-2 pr-sm-0 pr-3">
            <b-button variant="secondary" @click.prevent="openGeneralLocationLocationBarcodeInput"
              :disabled="savingMovement">
              <i class="fa fa-barcode"></i>
            </b-button>
          </div>
          <div class="col-10 pl-sm-0 pl-3">
            <model-list-select
              v-if="selectItem.locals"
              :list="selectItem.locals"
              option-value="id"
              option-text="name"
              v-model="generalLocation"
              placeholder="Selecione o Endereço Geral"
              @searchchange="searchAllocation"
              :filterPredicate="alwaysTrueFilterPredicate"/>
          </div>
        </div>
        <div class="row justify-content-sm-end justify-content-center mt-3">
          <div class="col-auto">
            <div v-if="savingMovement" class="col-auto pr-sm-0 pr-3">
              <loading-animation />
            </div>
            <b-button v-else variant="primary" @click.prevent="allocateAllItemLotsToTheSameLocation"
              :disabled="!generalLocation || !generalLocation.id">
              <i class="fa fa-check"></i> Confirmar
            </b-button>
          </div>
        </div>
      </b-container>
    </b-modal>

    <b-modal
      centered
      lazy
      title="Imprimir páginas dos códigos de barra"
      header-bg-variant="info"
      footer-border-variant="info"
      v-model="showBarcodePagesPrintModal"
      :hide-footer="true"
      id="barcodes-pages-print-modal"
    >
      <b-container class="px-0">
        <div v-if="!barcodePages || !barcodePages.length">
          <div class="row bg-light mx-0 py-3">
            <div class="col text-center">
              Nenhuma Página encontrada
            </div>
          </div>
        </div>

        <div v-if="barcodePages && barcodePages.length">
          <div v-for="(barcodePage, index) in barcodePages" class="row py-3 border mx-0 align-items-center" :key="index"
            :class="{ 'bg-light': index % 2 != 0 }">
            <div class="col">
              Página {{ index + 1 }} ({{ (index > 0 ? barcodePages[index - 1].length * index : 0) + 1 }} - {{ (index > 0 ? barcodePages[index - 1].length * index : 0) + barcodePages[index].length }})
            </div>
            <h4 v-if="!loadingBarcodePDF && barcodePagesPrinted.includes(index)" class="col-auto mb-0 pr-0">
              <i class="cui-check text-success"></i>
            </h4>
            <div class="col-auto">
              <div v-if="loadingBarcodePDF" class="mr-5">
                <loading-animation />
              </div>
              <b-button v-else :variant="barcodePagesPrinted.includes(index) ? 'secondary' : 'primary'" @click.prevent="printBarcodePage(index)">
                <i class="fa fa-print"></i> Imprimir
              </b-button>
            </div>
          </div>
        </div>
      </b-container>
    </b-modal>

    <order-attendance-fab
      ref="orderAttendanceFab"
      type="ALOC"
      :orderId="$route.params.id"
    ></order-attendance-fab>
    <order-attendance-warning
      v-if="order && order.id"
      :orderId="order.id"
    ></order-attendance-warning>
    <allocation-suggestions-modal
      v-if="order && order.id"
      :order="order"
      :onHidden="onHidden"
      :loadNextItemLotMovements="loadWholeOrderNextItemLotMovements"
      :loadingItemLotMovements="loadingWholeOrderItemLotMovements"
      :itemLotMovements="wholeOrderItemLotMovements"
      :updateItemLotMovements="updateWholeOrderItemLotMovements"
      :isItemLotMovementsLastPage="wholeOrderIsItemLotMovementsLastPage"
      :config="config"
      :authUser="authUser"
      :parameter="parameter"
      ref="allocationSuggestionsModal"
    ></allocation-suggestions-modal>
  </div>
</template>

<script>
  import { order as service } from '@/service'
  import { TimelineMax  } from 'greensock'
  import { ModelListSelect } from 'vue-search-select'
  import { LoadingAnimation } from '@/components/loaders'
  import { httpClient } from '@/service'
  import BarcodeInputModal from '@/components/common/barcode-input-modal'
  import Axios from 'axios'
  import shared from '@/shared/shared'
  import ConfirmationModal from '@/components/common/confirmation-modal'
  import OrderItemFormModal from '@/components/forms/order-item-form-modal';
  import OrderAttendanceFAB from '@/components/common/order-attendance-fab';
  import OrderAttendanceWarning from '@/components/common/order-attendance-warning';
  import AllocationSuggestionsModal from '@/components/modals/allocation-suggestions-modal';
  import { auth } from "@/service";
  import orderUtils from '@/shared/order-utils'

  export default {
    name: 'items-in-start',
    components: {
      ModelListSelect,
      LoadingAnimation,
      BarcodeInputModal,
      ConfirmationModal,
      OrderItemFormModal,
      'order-attendance-fab': OrderAttendanceFAB,
      OrderAttendanceWarning,
      AllocationSuggestionsModal
    },

    data () {
      return {
        loadingAllocations: false,
        tl: new TimelineMax(),
        modalShow: false,
        selectedLocal: {},
        select: false,
        selectItem: {},
        order: {},
        quantity: null,
        manualSelectionNumberOfVolumes: null,
        isComplete: false,
        fields: [
          { key: 'name', label:'Item'},
        ],
        loadingItemLots: false,
        savingMovement: false,
        savingOrder: false,
        locationSearchTimeout: null,
        loadingLocationBarcode: false,
        locationBarcodeAttempts: 0,
        loadingLotBarcode: false,
        lotBarcodeAttempts: 0,
        showBarcodesPrintModal: false,
        loadingBarcodePDF: false,
        currentLocationQuantity: 0,
        orderItemLots: null,
        wereItemLotsAutomaticallyCreated: false,
        selectedLot: {},
        loadingBalances: false,
        loadingLots: false,
        showItemLotCreatingForm: false,
        newItemLot: {
          lotNumber: null,
          createLotNumberAutomatically: false,
          barcode: null,
          createBarcodeAutomatically: true
        },
        newItemLotInvalidLotNumberMessage: 'Por favor informe o número do lote',
        newItemLotInvalidBarcodeMessage: 'Por favor informe o código de barras do lote',
        newItemLotInvalidQuantityPerVolumeMessage: 'Por favor informe a quantidade por volume do lote',
        newItemLotInvalidNofVolumesMessage: 'Por favor informe o número de volumes do lote',
        creatingNewItemLot: false,
        showSelectedLotBarcodesModal: false,
        barcodesPrintOrderItemLots: null,
        barcodesPrintOrderItemLotsIndex: null,
        showItemLotListModal: false,
        quantityPerVolume: 0,
        loadingOrder: false,
        printCreatedLotsBarcodes: true,
        lotsToBeCreatedSum: 0,
        isAnyLotToBeCreatedZero: false,
        lotSuggestionDeleteIndex: 0,
        wasItemLotCreationCalledFromSuggestion: false,
        lotSuggestions: [],
        creatingItemLotSuggestions: false,
        selectedItemOrderItemLots: [],
        showSelectItemBarcodesPrintModal: false,
        loadingSelectedItemOrderItemLotsBalance: false,
        haveSelectedItemOrderItemLotsAtLeastOneBalance: false,
        showlotSuggestionsMovementsModal: false,
        selectedLotSuggestionIndex: -1,
        itemLotSuggestionLocationBarcodeAttempts: 0,
        loadingItemLotSuggestionLocationBarcode: false,
        itemLotSuggestionSelectedByBarcodeIndex: -1,
        itemLotSuggestionLotBarcodeAttempts: 0,
        loadingItemLotSuggestionLotBarcode: false,
        itemLotSuggestionPrefix: null,
        editingSuggestionQuantity: false,
        orderFinishModalShow: false,
        atLeastOneOrderItemUnfinished: false,
        generalLocation: {},
        generalLocationBarcodeAttempts: 0,
        loadingGeneralLocationBarcode: false,
        showAllocateAllItemLotsToTheSameLocationModal: false,
        deleteIndex: null,
        barcodePrintModel: null,
        barcodePrintModels: [],
        config: {},
        atLeastOneOrderItemWithAutomaticItemLot: false,
        showBarcodePagesPrintModal: false,
        barcodePages: [],
        barcodePagesPrinted: [],
        lotSuggestionQuantity: 0,
        itemLot: null,
        showQuantityPerVolumeModal: false,
        quantityPerVolumeBarcodeAttempts: 0,
        loadingQuantityPerVolumeBarcode: false,
        loadingWholeOrderItemLotMovements: false,
        wholeOrderItemLotMovements: [],
        wholeOrderIsItemLotMovementsLastPage: false,
        authUser: null,
        parameter: {}
      }
    },

    mounted: function () {
      this.config = shared.getConfig();
      this.authUser = shared.getAuthUser();
      this.parameter = shared.getParameterFromAuthUser(this.authUser);
      this.barcodePrintModel = this.config.printConfig.model;
      this.findOrder();
      this.$nextTick(() => {
        this.getItemLotPrintModels();
      });
    },

    beforeRouteLeave (to, from, next) {
      const options = {
        title: 'Você está prestes a parar sua tarefa de alocação!',
        size: 'sm',
        okLabel: 'Sim',
        cancelLabel: 'Não',
      }

      if (!this.isComplete) {
        this.$dialogs.confirm(
          'Tem certeza de que deseja sair?',
          options
        ).then(res => {
          if (res.ok) {
            this.stopPrepare()
          }
          next(res.ok)
        })
      } else {
        next(true)
      }
    },

    computed: {
      modalTitle: function() {
        return `Item: ${this.selectItemLabel}  -- Pendente:  ${this.subtract(this.selectItem.quantity, this.selectItem.alocatedQuantity)}`
      },

      selectItemLabel() {
        return this.selectItem && this.selectItem.item ? this.getItemReduzidoFromRef(this.selectItem.item.ref) + ' - ' + this.selectItem.item.description : '';
      },

      localsAndLotSelected() {
        if (!this.selectedLocal || !this.selectedLot) {
          return false;
        }

        let result = this.selectedLocal.id && this.selectedLot.id;

        if (result) {
          if (this.wereItemLotsAutomaticallyCreated || this.isSelectItemItemLotAutomatic) {
            this.getLocationQuantity();
          } else {
            this.getLocationAndLotQuantity();
            let remainingQuantity = this.subtract(this.selectItem.quantity, this.selectItem.alocatedQuantity || 0);
            let isDefaultQuantityBehaviorRemainingQuantity = this.config
              && this.config.manualSelectionConfig
              && this.config.manualSelectionConfig.defaultQuantityBehavior === 'remaining_quantity';

            if (this.selectedLot.quantityPerVolume
              && this.selectedLot.quantityPerVolume < remainingQuantity) {

              if (isDefaultQuantityBehaviorRemainingQuantity) {
                this.manualSelectionNumberOfVolumes = Math.floor(remainingQuantity / this.selectedLot.quantityPerVolume);
              } else {
                this.manualSelectionNumberOfVolumes = 1;
              }

            } else {
              this.manualSelectionNumberOfVolumes = 0;

              if (isDefaultQuantityBehaviorRemainingQuantity) {
                this.quantity = remainingQuantity;
              }
            }
          }
        }

        return result;
      },

      newItemLotNumberInvalidFeedback() {
        if (!this.isNewItemLotNumberValid()) {
          return this.newItemLotInvalidLotNumberMessage;
        } else {
          return '';
        }
      },

      newItemLotBarcodeInvalidFeedback() {
        if (!this.isNewItemBarcodeValid()) {
          return this.newItemLotInvalidBarcodeMessage;
        } else {
          return '';
        }
      },

      newItemLotQuantityPerVolumeInvalidFeedback() {
        if (this.newItemLot.quantityPerVolume) {
          return '';
        } else {
          return this.newItemLotInvalidQuantityPerVolumeMessage;
        }
      },

      newItemLotNofVolumesInvalidFeedback() {
        if (this.newItemLot.tempNofVolumes) {
          return '';
        } else {
          return this.newItemLotInvalidNofVolumesMessage;
        }
      },

      isSelectItemItemLotAutomatic() {
        return this.selectItem && this.selectItem.item && this.selectItem.item.automaticItemLot;
      },

      disableLotCreation() {
        return (this.parameter && this.parameter.disableLotCreation);
      },

      autoOpenCamera() {
        return shared.autoOpenCamera(this.parameter, this.config);
      },
    },

    methods: {
      async getItemLotPrintModels() {
        this.barcodePrintModels = await shared.getItemLotPrintModels(httpClient, { lotOnly: true });
      },

      async createItemLots(createItemLotsForAllOrderItems) {
        if (createItemLotsForAllOrderItems || this.atLeastOneOrderItemWithAutomaticItemLot) {
          if (createItemLotsForAllOrderItems) {
            this.wereItemLotsAutomaticallyCreated = true;
          }

          if (this.order && this.order.orderItems && this.order.orderItems.length) {
            for (let i = 0; i < this.order.orderItems.length; i++) {
              let orderItem = this.order.orderItems[i];

              if (createItemLotsForAllOrderItems || (orderItem.item && orderItem.item.automaticItemLot)) {
                if (this.orderItemLots && this.orderItemLots.length) {
                  let foundItemLot;
                  for (let j = 0; j < this.orderItemLots.length; j++) {
                    let orderItemLot = this.orderItemLots[j];

                    if (orderItemLot && orderItemLot.orderItem && orderItemLot.orderItem.id === orderItem.id) {
                      foundItemLot = orderItemLot.itemLot;

                      if (!orderItem.orderItemLots) {
                        orderItem.orderItemLots = [];
                      }

                      orderItem.orderItemLots.push(orderItemLot);
                    }
                  }

                  if (foundItemLot) {
                    orderItem.areLotsConfirmed = true;
                    continue;
                  }
                }

                this.loadingItemLots = true;
                httpClient.post(`${process.env.VUE_APP_API_URL}order-item-lots/`, {
                  orderItem: orderItem,
                  itemLot: {
                    item: orderItem.item,
                    lotNumber: this.order.number,
                    companyGroup: this.order.companyGroup
                  },
                  companyGroup: this.order.companyGroup
                }).then(data => {
                  this.loadingItemLots = false;
                  console.log('Lote do item ' + orderItem.item.description + ' criado com sucesso');

                  if (!orderItem.orderItemLots) {
                    orderItem.orderItemLots = [];
                  }

                  orderItem.orderItemLots.push(data.data.data);
                  orderItem.areLotsConfirmed = true;

                  if (this.orderItemLots) {
                    this.orderItemLots = [];
                  }

                  if (!this.orderItemLots.filter(oil => oil.id === data.data.data.id).length) {
                    this.orderItemLots.push(data.data.data);
                  }
                }).catch(error => {
                  this.loadingItemLots = false;
                  if (error.message) {
                    this.$notify.error(error)
                  } else if (orderItem && orderItem.item) {
                    this.$notify.textError('Houve um erro ao criar o lote do item ' + orderItem.item.description)
                  } else {
                    this.$notify.textError('Houve um erro ao criar o lote de um dos itens')
                  }
                });
              }
            }

            this.reloadOrder();
          }
        }
      },

      stopPrepare () {
        //const { _links } = localStorage.getItem('task/currentTask');

        const formData = {
          time: new Date()
        }

        //await service.stopPrepare(_links, formData)
        this.$notify.success('Alocação parada com sucesso!', 'Parada!')
      },

      animate: function (box) {
        this.tl.staggerFrom(box, 0.3, {
          scale: 0,
          opacity: 0
        }, .1)
      },

      onShow() {
        this.quantity = this.subtract(this.selectItem.quantity, this.selectItem.alocatedQuantity);
        //await this.$store.dispatch('allocation/getAllAvailable', item.locals[0].warehouse)

        this.selectedLocal = {};
        this.selectedLot = {};
        this.modalShow = true;
        this.manualSelectionNumberOfVolumes = 1;
        //const available = await localStorage.getItem('allocation/getFiveAvailable');
        this.searchAllocation(null);

        if (this.wereItemLotsAutomaticallyCreated || this.isSelectItemItemLotAutomatic) {
          this.setSelectedLotFromAutomaticallyCreatedOne();
        } else {
          this.searchLot(null);
        }
      },

      addItem() {
        /*const { id } = this.selectItem
        const amount = this.quantity
        let { name } = this.selectedLocal

        name = name.replace(' disponível', '')*/

        //await service.incrementOrderItem({ uuid, amount, name })

        if (this.order.transfer && this.selectedItemOrderItemLots) {
          if (!this.selectedLot || !this.selectedLot.id) {
            return;
          }

          let selectedLotAvailableOrderItemLots = this.selectedItemOrderItemLots
            .filter(oil => oil.itemLot && oil.itemLot.id === this.selectedLot.id);

          if (!selectedLotAvailableOrderItemLots.length) {
            this.$notify.textError('O lote selecionado não está entre os lotes liberados pelo pedido de saída dessa transferência');
            return;
          }

          if (!this.quantity
            || Number(this.quantity) > (selectedLotAvailableOrderItemLots[0].quantity || 0) - (selectedLotAvailableOrderItemLots[0].alocatedQuantity || 0)) {
            this.$notify.textError('A quantidade informada não foi disponibilizada pelo pedido de saída dessa transferência');

            return;
          }
        }

        this.savingMovement = true;
        httpClient.post(`${process.env.VUE_APP_API_URL}item-lot-movements/`, {
          quantity: this.quantity,
          orderItem: this.selectItem,
          itemLot: this.selectedLot,
          location: this.selectedLocal,
          companyGroup: this.order.companyGroup,
        }).then(data => {
          this.savingMovement = false;
          this.$notify.success('Movimento do item ' + this.selectItem.item.description + ' salvo com sucesso');
          let locals = this.selectItem.locals;
          let lotsAvailable = this.selectItem.lotsAvailable;
          let areLotsConfirmed = this.selectItem.areLotsConfirmed;

          this.selectItem = data.data.data.orderItem;
          this.selectItem.locals = locals;
          this.selectItem.lotsAvailable = lotsAvailable;
          this.selectItem.areLotsConfirmed = areLotsConfirmed;

          this.quantity = this.subtract(this.selectItem.quantity, this.selectItem.alocatedQuantity);
          this.selectedLocal = data.data.data.location;

          if (this.wereItemLotsAutomaticallyCreated || this.isSelectItemItemLotAutomatic) {
            this.getLocationQuantity();
          } else {
            this.getLocationAndLotQuantity();
          }

          for (let i = 0; i < this.order.orderItems.length; i++) {
            if (this.order.orderItems[i].id === this.selectItem.id) {
              this.order.orderItems[i] = this.selectItem;
              break;
            }
          }

          if (this.selectItem.alocatedQuantity >= this.selectItem.quantity) {
            this.$bvModal.hide('movements-modal');
            this.$bvModal.hide('item-lot-suggestion-movement-modal');
            this.$bvModal.hide('item-lot-list-modal');
          } else if (!this.wereItemLotsAutomaticallyCreated && !this.isSelectItemItemLotAutomatic) {
            this.searchSelectedItemOrderItemLots(this.selectedItemOrderItemLotsCallback);
          }
        }).catch(error => {
          this.savingMovement = false;
          if (error.message) {
            this.$notify.error(error)
          } else {
            this.$notify.textError('Houve um erro ao salvar a movimentação do item ' + this.selectItem.item.description)
          }
        });
      },

      onHidden(event) {
        if (event) {
          event.preventDefault();
        }

        this.selectedLocal = {};
        orderUtils.sortOrderItems(this.order);

        if (this.shouldFinishOrderAutomatically()) {
          const result = this.order.orderItems.filter(orderItem => orderItem.alocatedQuantity < orderItem.quantity);
          if (result.length === 0) {
            this.finishOrder();
          }
        }
      },

      finishOrder() {
        this.savingOrder = true;
        httpClient.patch(`${process.env.VUE_APP_API_URL}orders/${this.order.id}`, {
          movementStatus: 'O',
          allocationEndDate: new Date().getTime(),
        }).then(data => {
          this.savingOrder = false;
          this.isComplete = true

          //const { _links } = localStorage.getItem('task/currentTask');

          const formData = {
            time: new Date()
          }

          //await service.completeOrder(_links, formData)
          setTimeout(this.redirect, 3000);
          this.orderFinishModalShow = false;
          //this.$root.$emit('reloadOrdersCounts');
        }).catch(error => {
          this.savingOrder = false;
          if (error.message) {
            this.$notify.error(error)
          } else {
            this.$notify.textError('Houve um erro ao finalizar o pedido. Por favor tente novamente')
          }
        });
      },

      redirect () {
        window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/');
      },

      getOrderItemLots(callback) {
        this.loadingItemLots = true;
        httpClient.post(`${process.env.VUE_APP_API_URL}order-item-lots/condition?page=${0}&size=${10000}`, {
          conditions: [{
            field: 'orderItem.order.id',
            value: this.order.id
          }]
        }).then(data => {
          this.loadingItemLots = false;
          this.orderItemLots = data.data.data.content;
          this.updateOrderItemsAreLotsConfirmed();

          if (callback) {
            callback();
          }
        }).catch(error => {
          this.loadingItemLots = false;
          if (error.message) {
            this.$notify.error(error)
          } else {
            this.$notify.textError('Houve um erro ao buscar os lotes do pedido')
          }
        });
      },

      searchAllocation (searchText) {
        if (!this.loadingAllocations && (
          (searchText && searchText.length) || !this.selectedLocal || Object.keys(this.selectedLocal).length == 0
        )) {
          if (this.locationSearchTimeout) {
            clearTimeout(this.locationSearchTimeout);
          }

          this.locationSearchTimeout = setTimeout(() => {
            this.loadingAllocations = true

            let conditions = [{
              logicalOperator: 'OR',
              conditions: [
                {
                  joinType: 'LEFT',
                  field: 'item.id',
                  conditionalOperator: 'EQUALS',
                  value: this.selectItem.item.id
                },
                {
                  joinType: 'LEFT',
                  field: 'item.id',
                  conditionalOperator: 'IS_NULL'
                }
              ]
            }];

            if (this.order && this.order.stockLocale) {
              conditions.push({
                field: 'stockLocale.id',
                value: this.order.stockLocale.id
              });
            }

            if (searchText && searchText.length) {
              conditions.push({
                field: 'name',
                conditionalOperator: 'LIKE_START',
                value: searchText
              });
            }

            httpClient
            .post(`${process.env.VUE_APP_API_URL}locations/condition?page=${0}&size=${10}`, {
              conditions: conditions
            })
            .then(({ data }) => {
              this.selectItem.locals = data.data.content
              let selectItem = this.selectItem;
              this.selectItem = {};
              this.selectItem = selectItem;

              this.loadingAllocations = false
            })
            .catch((error) => {
              if (error.message) {
                this.$notify.error(error)
              }
              this.selectItem.locals = [];
              this.loadingAllocations = false
            });
          }, 500);
        }
      },

      setOrderStartDate() {
        if (!this.order.allocationStartDate || this.noQuantityAlocated()) {
          this.order.allocationStartDate = new Date();
          this.savingOrder = true;
          httpClient.patch(`${process.env.VUE_APP_API_URL}orders/${this.order.id}`, {
            allocationStartDate: this.order.allocationStartDate
          }).then(data => {
            this.savingOrder = false;
          }).catch(error => {
            this.savingOrder = false;
            if (error.message) {
              this.$notify.error(error)
            } else {
              this.$notify.textError('Houve um erro ao atribuir a data de início do pedido. Por favor saia e tente novamente')
            }
          });
        }
      },

      noQuantityAlocated() {
        if (this.order && this.order.orderItems) {
          for (let i = 0; i < this.order.orderItems.length; i++) {
            if (this.order.orderItems[i].alocatedQuantity) {
              return false;
            }
          }
        }

        return true;
      },

      openLocationBarcodeInput() {
        this.$bvModal.show('location-barcode-input-modal');
      },

      readLocationBarcode(barcode) {
        if (!this.loadingLocationBarcode) {
          if (barcode && barcode.length) {
            this.loadingLocationBarcode = true;
            httpClient.get(`${process.env.VUE_APP_API_URL}locations/barcode/${barcode}`)
            .then(data => {
              this.loadingLocationBarcode = false;

              if (data.data.data) {
                if (data.data.data.item && data.data.data.item.id !== this.selectItem.item.id) {
                  this.$notify.textError('Endereço não é compatível com este item');
                } else if (this.order.stockLocale && data.data.data.stockLocale.id !== this.order.stockLocale.id) {
                  this.$notify.textError('Endereço não é compatível com este armazém');
                } else {
                  this.selectItem.locals = [
                    data.data.data
                  ];
                  let selectItem = this.selectItem;
                  this.selectItem = {};
                  this.selectItem = selectItem;
                  this.selectedLocal = data.data.data;
                  this.$bvModal.hide('location-barcode-input-modal');
                  this.$notify.success('Endereço encontrado')
                }
              } else {
                this.$notify.textError('Endereço não encontrado')
              }

              this.locationBarcodeAttempts++;
            }).catch(error => {
              this.loadingLocationBarcode = false;
              if (error.message) {
                this.$notify.error(error)
              } else {
                this.$notify.textError('Houve um erro ao buscar o endereço pelo código de barras. Por favor tente novamente')
              }

              this.locationBarcodeAttempts++;
            });
          } else {
            this.$notify.textError('Código inválido. Por favor insira um código de barras válido')
          }
        }

        this.locationBarcodeAttempts++;
      },

      openBarcodesPrintModal() {
        this.showBarcodesPrintModal = true;

        this.getOrderItemLots(() => {
          if (this.orderItemLots) {
            this.orderItemLots.forEach(orderItemLot => {
              orderItemLot.print = true;
              orderItemLot.printQuantity = 1;
            });
          }

          let orderItemLots = this.orderItemLots;
          this.orderItemLots = [];
          this.orderItemLots.push(...orderItemLots);
        });
      },

      closeBarcodePrintModal() {
        this.showBarcodesPrintModal = false;
      },

      printBarcodes() {
        this.barcodePages = [];
        this.barcodePagesPrinted = [];

        let barcodeModels = shared.getBarcodeModelsFromItemLots([
          this.barcodesPrintOrderItemLots[this.barcodesPrintOrderItemLotsIndex]
        ].map(oil => {
          let il = oil.itemLot;
          il.printBarcode = true;
          il.printQuantity = 1;

          return il;
        }), this.barcodePrintModel);

        if (barcodeModels.length) {
          this.barcodePages = shared.paginateArrayInPages(barcodeModels, 100);

          if (this.barcodePages.length === 1) {
            this.printBarcodePage(0);
          } else {
            this.showBarcodePagesPrintModal = true;
          }
        } else {
          this.$notify.textError('Nenhuma impressão selecionada');
        }
      },

      printBarcodePage(index) {
        this.loadingBarcodePDF = true;
        this.$notify.warn('Gerando página ' + (index + 1) + ' de ' + this.barcodePages.length);
        let errorMsg = 'Houve um erro ao gerar o PDF. Por favor tente novamente ou entre em contato com a Teorema Sistemas';

        if (this.barcodePrintModel.value && this.barcodePrintModel.value.id) {
          this.config.printConfig.templateId = this.barcodePrintModel.value.id;
          this.config.printConfig.model = null;
        } else {
          this.config.printConfig.model = this.barcodePrintModel.value;
          this.config.printConfig.templateId = null;
        }

        Axios({
          url: process.env.VUE_APP_API_URL + `item-lots/barcode/pdf`,
          method: 'POST',
          data:  {
            models: this.barcodePages[index],
            options: this.config.printConfig
          },
          responseType: 'blob',
          headers: auth.getAxiosHeaders()
        }).then((response) => {
          this.loadingBarcodePDF = false;

          if (response && response.data && response.data.size) {
            let file = new Blob([response.data], { type: 'application/pdf' });
            let url = window.URL.createObjectURL(file);
            window.open(url);

            this.barcodesPrintOrderItemLots[this.barcodesPrintOrderItemLotsIndex].printed = true;
            if (!this.barcodePagesPrinted.includes(index)) {
              this.barcodePagesPrinted.push(index);
            }

            if (this.barcodePagesPrinted.length >= this.barcodePages.length) {
              this.showBarcodePagesPrintModal = false;
              this.$bvModal.hide('barcodes-pages-print-modal');

              if (!this.barcodesPrintOrderItemLots.find(oil => !oil.printed)) {
                this.showSelectItemBarcodesPrintModal = false;
                this.showBarcodesPrintModal = false;
                this.$bvModal.hide('select-item-barcodes-print-modal');
                this.$bvModal.hide('barcodes-print-modal');
              }
            }
          } else {
            this.$notify.textError(errorMsg);
          }
        }).catch((error) => {
          this.loadingBarcodePDF = false;

          if (error.response && error.response.headers && error.response.headers.errormessage) {
            this.$notify.textError(error.response.headers.errormessage);
          } else if (error.message) {
            this.$notify.error(error);
          } else {
            this.$notify.textError(errorMsg);
          }
        });
      },

      reloadOrder() {
        let order = this.order;
        this.order = {};
        this.order = order;
      },

      async getLocationQuantity() {
        if (!this.selectItem || !this.selectedLocal) {
          return 0;
        }

        this.loadingBalances = true;
        return await httpClient.get(
          `${process.env.VUE_APP_API_URL}locations/quantity/${this.selectedLocal.id}/item/${this.selectItem.item.id}`
        ).then(({ data }) => {
          this.loadingBalances = false;
          this.currentLocationQuantity = data.data;
          return this.currentLocationQuantity;
        })
        .catch((error) => {
          this.loadingBalances = false;
          if (error.message) {
            this.$notify.error(error)
          }
          return 0
        });
      },

      getItemReduzidoFromRef(ref) {
        return shared.getItemReduzidoFromRef(ref);
      },

      getLocationAndLotQuantity() {
        if (!this.selectItem || (!this.selectedLocal && !this.selectedLot)) {
          return 0;
        }

        let conditions = [{
          field: 'itemLot.item.id',
          value: this.selectItem.item.id
        }];

        if (this.selectedLocal) {
          conditions.push({
              field: 'location.id',
              value: this.selectedLocal.id
          });
        }

        if (this.selectedLot) {
          conditions.push({
              field: 'itemLot.id',
              value: this.selectedLot.id
          });
        }

        this.loadingBalances = true;
        httpClient
        .post(`${process.env.VUE_APP_API_URL}balances/condition?page=${0}&size=${1}`, {
          conditions: conditions
        })
        .then(({ data }) => {
          this.loadingBalances = false;
          if (data.data.content.length) {
            this.matchedBalance = data.data.content[0];
            this.currentLocationQuantity = (this.matchedBalance.entries || 0) - (this.matchedBalance.exits || 0);
          } else {
            this.currentLocationQuantity = 0;
          }
        })
        .catch((error) => {
          this.loadingBalances = false;
          if (error.message) {
            this.$notify.error(error)
          }
        });
      },

      searchLot (searchText) {
        if (!this.loadingLots && (
          (searchText && searchText.length) || !this.selectedLot || Object.keys(this.selectedLot).length == 0
        )) {
          if (this.lotSearchTimeout) {
            clearTimeout(this.lotSearchTimeout);
          }

          this.lotSearchTimeout = setTimeout(() => {
            this.loadingLots = true

            let conditions = [{
              field: 'item.id',
              conditionalOperator: 'EQUALS',
              value: this.selectItem.item.id
            }];

            if (searchText && searchText.length) {
              conditions.push({
                field: 'lotNumber',
                conditionalOperator: 'LIKE_START',
                value: searchText
              });
            }

            if (this.order.transfer && this.selectedItemOrderItemLots && this.selectedItemOrderItemLots.length) {
              conditions.push({
                field: 'orderItemLots.id',
                conditionalOperator: 'IN',
                value: this.selectedItemOrderItemLots.map(oil => oil.id)
              });
            }

            httpClient
            .post(`${process.env.VUE_APP_API_URL}item-lots/condition?page=${0}&size=${10}&sort=createdAt,asc`, {
              conditions: conditions
            })
            .then(({ data }) => {
              this.selectItem.lotsAvailable = data.data.content;
              let selectItem = this.selectItem;
              this.selectItem = {};
              this.selectItem = selectItem;

              if (data.data.content.length) {
                this.selectedLot = data.data.content[0];
              }

              this.loadingLots = false
            })
            .catch((error) => {
              if (error.message) {
                this.$notify.error(error)
              }
              this.selectItem.lotsAvailable = [];
              this.loadingLots = false
            });
          }, 500);
        }
      },

      setSelectedLotFromAutomaticallyCreatedOne() {
        if (this.selectItem && this.selectItem.orderItemLots && this.selectItem.orderItemLots.length) {
          let orderNumber = this.order.number;
          if (orderNumber) {
            orderNumber = orderNumber.toString();
          }

          let foundOrderItemLots = this.selectItem.orderItemLots.filter(oil => {
            return oil.itemLot && oil.itemLot.lotNumber === orderNumber
          });

          if (foundOrderItemLots && foundOrderItemLots.length) {
            this.selectedLot = foundOrderItemLots[0].itemLot;
          } else {
            this.selectedLot = this.selectItem.orderItemLots[0].itemLot;
          }
        }
      },

      openLotCreationForm(fromSuggestion) {
        this.wasItemLotCreationCalledFromSuggestion = fromSuggestion;

        this.newItemLot = {
          lotNumber: null,
          createLotNumberAutomatically: this.newItemLot.createLotNumberAutomatically,
          barcode: null,
          createBarcodeAutomatically: this.newItemLot.createBarcodeAutomatically,
          tempNofVolumes: 1,
          quantity: '?'
        };

        if (this.wasItemLotCreationCalledFromSuggestion) {
          this.newItemLot.quantity = 0;
          let highestSuffix = 1;

          if (this.lotSuggestions && this.lotSuggestions.length > 1) {
            this.lotSuggestions.forEach(lotSuggestion => {
              if (lotSuggestion.itemLot && lotSuggestion.itemLot.lotNumber) {
                let index = lotSuggestion.itemLot.lotNumber.indexOf('-');

                if (index > -1 && index < lotSuggestion.itemLot.lotNumber.length - 1) {
                  let suffix = Number(lotSuggestion.itemLot.lotNumber.substring(index + 1));

                  if (suffix > highestSuffix) {
                    highestSuffix = suffix;
                  }
                }
              }
            });
          }

          this.newItemLot.lotNumber = this.itemLotSuggestionPrefix + '-' + (Number(highestSuffix) + 1);
        }

        this.showItemLotCreatingForm = true;

        let _this = this;
        setTimeout(() => {
          if (_this.$refs.newItemLotQuantityPerVolumeInput) {
            _this.$refs.newItemLotQuantityPerVolumeInput.$el.focus();
          }
        }, 500);
      },

      updateNewItemLotQuantity() {
        if (this.newItemLot.tempNofVolumes && this.newItemLot.tempNofVolumes.toString().length) {
          this.newItemLot.tempNofVolumes = Number(this.newItemLot.tempNofVolumes);
        }

        if (this.newItemLot.quantityPerVolume && this.newItemLot.quantityPerVolume.toString().length) {
          this.newItemLot.quantityPerVolume = Number(this.newItemLot.quantityPerVolume);
        }

        if (!isNaN(this.newItemLot.quantityPerVolume) && !isNaN(this.newItemLot.quantityPerVolume)) {
          this.newItemLot.quantity = this.newItemLot.tempNofVolumes * this.newItemLot.quantityPerVolume;
        } else {
          this.newItemLot.quantity = '?';
        }
      },

      isNewItemLotNumberValid() {
        return !(!this.newItemLot.createLotNumberAutomatically && (!this.newItemLot.lotNumber || !this.newItemLot.lotNumber.length));
      },

      isNewItemBarcodeValid() {
        return !(!this.newItemLot.createBarcodeAutomatically && (!this.newItemLot.barcode || !this.newItemLot.barcode.length));
      },

      openLotBarcodeInput() {
        this.$bvModal.show('lot-barcode-input-modal');
      },

      readLotBarcode(barcode) {
        if (!this.loadingLotBarcode) {
          if (barcode && barcode.length) {
            this.loadingLotBarcode = true;
            httpClient.get(`${process.env.VUE_APP_API_URL}item-lots/barcode/${barcode}`)
            .then(data => {
              this.loadingLotBarcode = false;

              if (data.data.data) {
                if (data.data.data.item.id !== this.selectItem.item.id) {
                  this.$notify.textError('Lote não é compatível com este item');
                } else {
                  this.selectItem.lotsAvailable = [
                    data.data.data
                  ];
                  let selectItem = this.selectItem;
                  this.selectItem = {};
                  this.selectItem = selectItem;
                  this.selectedLot = data.data.data;
                  this.$bvModal.hide('lot-barcode-input-modal');
                  this.$notify.success('Lote encontrado')
                }
              } else {
                this.$notify.textError('Lote não encontrado')
              }

              this.lotBarcodeAttempts++;
            }).catch(error => {
              this.loadingLotBarcode = false;
              if (error.message) {
                this.$notify.error(error)
              } else {
                this.$notify.textError('Houve um erro ao buscar o lote pelo código de barras. Por favor tente novamente')
              }

              this.lotBarcodeAttempts++;
            });
          } else {
            this.$notify.textError('Código inválido. Por favor insira um código de barras válido')
          }
        }

        this.lotBarcodeAttempts++;
      },

      createItemLot() {
        if (!this.isNewItemLotNumberValid()) {
          this.$notify.textError(this.newItemLotInvalidLotNumberMessage);
          return;
        }

        if (!this.isNewItemBarcodeValid()) {
          this.$notify.textError(this.newItemLotInvalidBarcodeMessage);
          return;
        }

        this.updateNewItemLotQuantity();

        if (this.wasItemLotCreationCalledFromSuggestion && !this.newItemLot.quantityPerVolume) {
          this.$notify.textError(this.newItemLotInvalidQuantityPerVolumeMessage);
          return;
        }

        this.newItemLot.item = this.selectItem.item;

        if (this.newItemLot.createLotNumberAutomatically && !this.wasItemLotCreationCalledFromSuggestion) {
          this.newItemLot.lotNumber = this.order.number;
        }

        let orderItemLot = {
          orderItem: this.selectItem,
          itemLot: this.newItemLot,
          createLotNumberAutomatically: this.newItemLot.createLotNumberAutomatically,
          createBarcodeAutomatically: this.newItemLot.createBarcodeAutomatically,
          validate: true
        };

        if (this.wasItemLotCreationCalledFromSuggestion) {
          orderItemLot.quantity = Number(this.newItemLot.quantity);
          orderItemLot.numberOfVolumes = Number(this.newItemLot.tempNofVolumes);

          if (this.lotSuggestions && this.lotSuggestions.find(oil => {
            return oil.itemLot && Number(oil.itemLot.quantityPerVolume) === this.newItemLot.quantityPerVolume}
          )) {
            this.$notify.textError('Já existe um lote com essa quantidade por volume na lista de lotes à serem criados');
            return;
          }
        }

        if (this.wasItemLotCreationCalledFromSuggestion) {
          if (this.lotSuggestions.find(oil => oil.itemLot && oil.itemLot.lotNumber === orderItemLot.itemLot.lotNumber)) {
            this.$notify.textError('Já existe um lote com esse número na lista de lotes à serem criados');
            return;
          }

          this.lotSuggestions.push(orderItemLot);
          this.showItemLotCreatingForm = false;
          this.$notify.success('Lote adicionado com sucesso');
          this.reloadLotsToBeCreatedSum();
        } else {
          this.creatingNewItemLot = true;
          httpClient.post(`${process.env.VUE_APP_API_URL}order-item-lots`, orderItemLot).then(data => {
            this.creatingNewItemLot = false;
            this.orderItemLots.push(data.data.data);
            let createdItemLot = data.data.data.itemLot;

            if (!this.selectItem.lotsAvailable) {
              this.selectItem.lotsAvailable = [];
            }

            let lotsAvailable = this.selectItem.lotsAvailable;
            this.selectItem.lotsAvailable = [];
            this.selectItem.lotsAvailable.push(...lotsAvailable);
            this.selectItem.lotsAvailable.push(createdItemLot);

            this.selectedLot = createdItemLot;

            this.showItemLotCreatingForm = false;
            this.$notify.success('Lote criado com sucesso');
          }).catch(error => {
            this.creatingNewItemLot = false;
            if (error.message) {
              this.$notify.error(error)
            } else {
              this.$notify.textError('Houve um erro ao criar o Lote. Por favor tente novamente')
            }
          });
        }
      },

      openSelectedLotPrintModal() {
        this.showSelectedLotBarcodesModal = true;
      },

      closeSelectedLotBarcodePrintModal() {
        this.showSelectedLotBarcodesModal = false;
      },

      printSelectedLotBarcodes() {
        if (!this.selectedLot || !Object.keys(this.selectedLot).length) {
          this.$notify.textError('Por favor selecione o Lote');
          return;
        }

        this.loadingBarcodePDF = true;
        this.selectedLot.printBarcode = true;
        this.selectedLot.printQuantity = 1;

        if (this.barcodePrintModel.value && this.barcodePrintModel.value.id) {
          this.config.printConfig.templateId = this.barcodePrintModel.value.id;
          this.config.printConfig.model = null;
        } else {
          this.config.printConfig.model = this.barcodePrintModel.value;
          this.config.printConfig.templateId = null;
        }

        Axios({
          url: process.env.VUE_APP_API_URL + `item-lots/barcode/pdf`,
          method: 'POST',
          data:  {
            models: shared.getBarcodeModelsFromItemLots([this.selectedLot], this.barcodePrintModel),
            options: this.config.printConfig
          },
          responseType: 'blob',
          headers: auth.getAxiosHeaders()
        }).then((response) => {
          this.loadingBarcodePDF = false;
          if (response && response.data && response.data.size) {
            let file = new Blob([response.data], { type: 'application/pdf' });
            let url = window.URL.createObjectURL(file);
            window.open(url);
            this.showSelectedLotBarcodesModal = false;
            this.$bvModal.hide('selected-lot-barcodes-print-modal');
          } else {
            this.$notify.textError('Houve um erro ao gerar o PDF. Por favor tente novamente');
          }
        }).catch((error) => {
          this.loadingBarcodePDF = false;

          if (error.response && error.response.headers && error.response.headers.errormessage) {
            this.$notify.textError(error.response.headers.errormessage);
          } else if (error.message) {
            this.$notify.error(error)
          } else {
            this.$notify.textError('Houve um erro ao gerar o PDF. Por favor tente novamente');
          }
        });
      },

      showLotSuggestions(orderItem) {
        if ((orderItem.alocatedQuantity || 0) >= (orderItem.quantity || 0)) {
          this.onHidden();
          return;
        }

        this.generalLocation = {};
        this.selectItem = orderItem;
        this.itemLotSuggestionPrefix = this.order.number || this.order.documentNumber;

        if (this.wereItemLotsAutomaticallyCreated || this.isSelectItemItemLotAutomatic) {
          this.onShow();
        } else if (orderItem.anyOrderItemLotsWithoutQuantityPerVolume) {
          this.searchSelectedItemOrderItemLots((result) => {
            this.showQuantityPerVolumeModal = true;
          });
        } else {
          this.quantityPerVolume = 0;
          this.searchSelectedItemOrderItemLots(this.selectedItemOrderItemLotsCallback);
        }
      },

      searchSelectedItemOrderItemLots(callback) {
        this.loadingItemLots = true;
        httpClient.post(`${process.env.VUE_APP_API_URL}order-item-lots/condition?page=${0}&size=${1000}&sort=itemLot.lotNumber,asc`, {
          conditions: [{
            field: 'orderItem.id',
            value: this.selectItem.id
          }]
        }).then(data => {
          this.loadingItemLots = false;
          this.selectedItemOrderItemLots = data.data.data.content;

          if (callback) {
            callback(data.data.data.content);
          }
        }).catch(error => {
          this.loadingItemLots = false;
          if (error.message) {
            this.$notify.error(error)
          } else {
            this.$notify.textError('Houve um erro ao buscar os lotes do item')
          }
        });
      },

      selectedItemOrderItemLotsCallback(result) {
        this.lotSuggestions = result;

        if (this.lotSuggestions && this.lotSuggestions.length) {
          this.reloadLotsToBeCreatedSum();
        } else {
          if (this.order.transfer) {
            this.$notify.textError('Esse item não possui nenhum lote liberado pelo pedido de saída dessa transferência');
            return;
          } else if (this.disableLotCreation) {
            this.$notify.textError('Esse item não possui nenhum lote');
            return;
          }

          this.getLotSuggestions();
        }

        if (this.selectItem.areLotsConfirmed || this.order.transfer) {
          if (this.lotSuggestions) {
            this.lotSuggestions.forEach(oil => {
              oil = this.setOrderItemLotUsedQuantity(oil);
            });
          }

          this.showlotSuggestionsMovementsModal = true;

          if (this.selectItem.areLotsConfirmed) {
            this.loadHaveSelectedItemOrderItemLotsAtLeastOneBalance();
          }

          if (this.autoOpenCamera) {
            this.$nextTick(() => {
              this.openItemLotSuggestionLotBarcodeInput();
            });
          }

          this.searchAllocation(null);
        } else {
          this.openItemLotListModal();
        }
      },

      getLotSuggestions() {
        if (this.itemLotSuggestionPrefix) {
          let remainingQuantity = this.subtract(this.selectItem.quantity, this.selectItem.alocatedQuantity);
          this.lotSuggestions = [];

          if (this.quantityPerVolume) {
            this.quantityPerVolume = Number(this.quantityPerVolume.toString().replace(',', '.'));
          }

          if (!this.quantityPerVolume || isNaN(this.quantityPerVolume)) {
            this.quantityPerVolume = remainingQuantity;
          }

          let numberOfDecimals = shared.countDecimals(this.quantityPerVolume);
          let remainingQuantityNumberOfDecimals = shared.countDecimals(remainingQuantity);
          if (remainingQuantityNumberOfDecimals > numberOfDecimals) {
            numberOfDecimals = remainingQuantityNumberOfDecimals;
          }

          let sequence = 1;

          while (remainingQuantity > 0) {
            let itemLotQuantity = Math.min(remainingQuantity, this.quantityPerVolume);

            let newOil = {
              orderItem: this.selectItem,
              itemLot: {
                item: this.selectItem.item,
                lotNumber: this.itemLotSuggestionPrefix + '-' + sequence,
                quantityPerVolume: itemLotQuantity
              },
              quantity: itemLotQuantity,
              numberOfVolumes: 1,
              validate: true,
              createLotNumberAutomatically: false,
              createBarcodeAutomatically: true
            };

            let index = this.lotSuggestions.findIndex(oil => oil.itemLot && oil.itemLot.quantityPerVolume === newOil.itemLot.quantityPerVolume);

            if (index > -1) {
              this.lotSuggestions[index].quantity = this.add(this.lotSuggestions[index].quantity, newOil.itemLot.quantityPerVolume);
              this.lotSuggestions[index].numberOfVolumes++;
            } else {
              this.lotSuggestions.push(newOil);
              sequence++;
            }

            remainingQuantity = shared.fixRounding(remainingQuantity - itemLotQuantity, numberOfDecimals);
          }

          this.reloadLotsToBeCreatedSum();
        }
      },

      setLotSuggestionManualQuantity(index) {
        this.editingSuggestionQuantity = false;
        this.lotSuggestions[index].numberOfVolumes = Number(this.lotSuggestions[index].numberOfVolumes);

        if (this.lotSuggestions[index].itemLot && this.lotSuggestions[index].itemLot.quantityPerVolume &&
         this.lotSuggestions[index].numberOfVolumes) {
          this.lotSuggestions[index].quantity = this.lotSuggestions[index].numberOfVolumes * this.lotSuggestions[index].itemLot.quantityPerVolume;
          this.reloadLotsToBeCreatedSum();
        }
      },

      findOrder() {
        this.loadingOrder = true;
        httpClient.get(`${process.env.VUE_APP_API_URL}orders/${this.$route.params.id}`)
        .then(data => {
          this.loadingOrder = false;
          this.order = data.data.data;
          orderUtils.sortOrderItems(this.order);
          this.setOrderStartDate();
          this.getOrderItemLots(() => {
            let askCreateLotsAutomatically = false;
            this.atLeastOneOrderItemWithAutomaticItemLot = false;
            let allOrderItemsAreWithAutomaticItemLot = true;

            if (this.order && !this.order.transfer && this.order.orderItems) {
              for (let i = 0; i < this.order.orderItems.length; i++) {
                if (!this.disableLotCreation && !this.order.orderItems[i].areLotsConfirmed && this.order.orderItems[i].item && !this.order.orderItems[i].item.automaticItemLot) {
                  askCreateLotsAutomatically = true;
                }

                if (this.order.orderItems[i].item && this.order.orderItems[i].item.automaticItemLot) {
                  this.atLeastOneOrderItemWithAutomaticItemLot = true;
                } else {
                  allOrderItemsAreWithAutomaticItemLot = false;
                }

                if (askCreateLotsAutomatically && this.atLeastOneOrderItemWithAutomaticItemLot && !allOrderItemsAreWithAutomaticItemLot) {
                  break;
                }
              }
            } else {
              allOrderItemsAreWithAutomaticItemLot = false;
            }

            if (allOrderItemsAreWithAutomaticItemLot) {
              this.createItemLots(true);
            } else if (askCreateLotsAutomatically) {
              this.$confirm({
                message: `Deseja criar os lotes automaticamente?`,
                button: { no: 'Não', yes: 'Sim' },
                callback: confirm => {
                  this.createItemLots(confirm);
                }
              });
            } else if (this.atLeastOneOrderItemWithAutomaticItemLot) {
              this.createItemLots(false);
            }
          });

          this.$store.dispatch('app/changePage', {
            title:'Executando Alocação no Armazém ' + (this.order && this.order.stockLocale ? this.order.stockLocale.name : null),
            size: 6
          });

          this.$nextTick(() => {
            const { box } = this.$refs
            this.animate(box);

            if (this.$refs && this.$refs.orderAttendanceFab) {
              this.$refs.orderAttendanceFab.startAutomaticAttendance();
            }
          });
        }).catch(error => {
          this.loadingOrder = false;
          if (error.message) {
            this.$notify.error(error)
          } else {
            this.$notify.textError("Houve um erro ao buscar o pedido");
          }
        });
      },

      reloadLotsToBeCreatedSum() {
        this.lotsToBeCreatedSum = 0;
        this.isAnyLotToBeCreatedZero = false;

        if (this.lotSuggestions && this.lotSuggestions.length) {
          let biggestNumberOfDecimals = 0;

          this.lotSuggestions.forEach(ls => {
            if (ls.quantity) {
              ls.quantity = Number(ls.quantity.toString().replace(',', '.'));
            }

            let numberOfDecimals = shared.countDecimals(ls.quantity);
            if (numberOfDecimals > biggestNumberOfDecimals) {
              biggestNumberOfDecimals = numberOfDecimals;
            }
          });

          this.lotSuggestions.forEach(ls => {
            if (ls.quantity) {
              this.lotsToBeCreatedSum = shared.fixRounding(this.lotsToBeCreatedSum + ls.quantity, biggestNumberOfDecimals);
            } else {
              this.isAnyLotToBeCreatedZero = true;
            }
          });
        }
      },

      openDeleteModal(index) {
        this.lotSuggestionDeleteIndex = index;
        this.$bvModal.show('lot-suggestion-delete-confirmation-modal');
      },

      removeLotSuggestion() {
        this.lotSuggestions.splice(this.lotSuggestionDeleteIndex, 1);
      },

      confirmLotSuggestionsCreation() {
        this.lotSuggestions.forEach(ls => {
          ls.validate = true;
        });

        this.creatingItemLotSuggestions = true;
        httpClient.post(
          `${process.env.VUE_APP_API_URL}order-item-lots/multiple`, this.lotSuggestions
        ).then(data => {
          this.creatingItemLotSuggestions = false;
          this.showItemLotListModal = false;
          this.$notify.success('Lotes criados com sucesso');
          this.selectedItemOrderItemLots = data.data.data;
          this.updateSelectedItemAreLotsConfirmed();

          if (this.printCreatedLotsBarcodes) {
            this.openSelectItemBarcodesPrintModal();
          }
        }).catch(error => {
          this.creatingItemLotSuggestions = false;
          if (error.message) {
            this.$notify.error(error)
          } else {
            this.$notify.textError('Houve um erro ao criar os Lotes. Por favor tente novamente')
          }
        });
      },

      openSelectItemBarcodesPrintModal() {
        this.selectedItemOrderItemLots.forEach(oil => {
          oil.print = true;
          oil.printQuantity = 1;
        });

        this.showSelectItemBarcodesPrintModal = true;
      },

      closeSelectItemBarcodePrintModal() {
        this.showSelectItemBarcodesPrintModal = false;
      },

      updateSelectedItemAreLotsConfirmed() {
        if (this.selectedItemOrderItemLots) {
          if (this.isSelectItemItemLotAutomatic && this.lotSuggestions.find(ls => {
            return ls.orderItem && ls.orderItem.item && ls.orderItem.item.id === this.selectItem.id
          })) {
            this.selectItem.areLotsConfirmed = true;
            return;
          }

          let sum = 0;
          let biggestNumberOfDecimals = 0;

          this.lotSuggestions.filter(ls => {
            ls.quantity = Number(ls.quantity.toString().replace(',', '.'));

            let numberOfDecimals = shared.countDecimals(ls.quantity);
            if (numberOfDecimals > biggestNumberOfDecimals) {
              biggestNumberOfDecimals = numberOfDecimals;
            }
          });

          this.selectedItemOrderItemLots.forEach(oil => {
            if (oil.quantity) {
              sum = shared.fixRounding(sum + oil.quantity, biggestNumberOfDecimals);
            }
          });

          if (sum >= this.subtract(this.selectItem.quantity, this.selectItem.alocatedQuantity)) {
            this.selectItem.areLotsConfirmed = true;
            return;
          }
        }

        this.selectItem.areLotsConfirmed = false;
      },

      updateOrderItemsAreLotsConfirmed() {
        if (this.order && this.order.orderItems && this.order.orderItems.length) {
          for (let i = 0; i < this.order.orderItems.length; i++) {
            let orderItem = this.order.orderItems[i];

            if (this.orderItemLots && this.orderItemLots.length) {
              let currentOrderItemOrderItemLots = this.orderItemLots.filter(oil => oil.orderItem && oil.orderItem.id === orderItem.id);

              if (this.order.transfer && !currentOrderItemOrderItemLots.length) {
                orderItem.doesNotHaveAnyTransferExitOrderItemLot = true;
              }

              if (currentOrderItemOrderItemLots.length && orderItem.item && orderItem.item.automaticItemLot) {
                orderItem.areLotsConfirmed = true;
                continue;
              }

              orderItem.anyOrderItemLotsWithoutQuantityPerVolume = this.anyOrderItemLotsWithoutQuantityPerVolume(currentOrderItemOrderItemLots);

              let sum = 0;
              currentOrderItemOrderItemLots.forEach(oil => {
                if (oil.quantity) {
                  sum += Number(oil.quantity);
                }
              });

              if (sum >= this.subtract(orderItem.quantity, orderItem.alocatedQuantity)) {
                orderItem.areLotsConfirmed = true;
                continue;
              }
            }

            orderItem.areLotsConfirmed = false;
          }
        }
      },

      loadHaveSelectedItemOrderItemLotsAtLeastOneBalance() {
        if (this.selectedItemOrderItemLots && this.selectedItemOrderItemLots.length) {
          for (let i = 0; i < this.selectedItemOrderItemLots.length; i++) {
            let oil = this.selectedItemOrderItemLots[i];

            if (oil.alocatedQuantity) {
              this.haveSelectedItemOrderItemLotsAtLeastOneBalance = true;
              return;
            }
          }

          let itemLotIds = [];

          this.selectedItemOrderItemLots.forEach(oil => {
            if (oil.itemLot && oil.itemLot.id) {
              itemLotIds.push(oil.itemLot.id);
            }
          });

          if (itemLotIds.length) {
            this.loadingSelectedItemOrderItemLotsBalance = true;
            httpClient.post(`${process.env.VUE_APP_API_URL}balances/condition?page=0&size=1`, {
              conditions: [{
                field: 'itemLot.id',
                conditionalOperator: 'IN',
                value: itemLotIds
              }]
            }).then(data => {
              this.loadingSelectedItemOrderItemLotsBalance = false;
              this.haveSelectedItemOrderItemLotsAtLeastOneBalance = Boolean(data.data.data.content.length);
            }).catch(error => {
              this.loadingSelectedItemOrderItemLotsBalance = false;
            });

            return;
          }
        }

        this.haveSelectedItemOrderItemLotsAtLeastOneBalance = false;
      },

      openLotListModalToRecreateItemLots() {
        this.showlotSuggestionsMovementsModal = false;
        this.lotSuggestions = this.selectedItemOrderItemLots;
        this.reloadLotsToBeCreatedSum();
        this.openItemLotListModal();
      },

      openItemLotListModal() {
        this.showItemLotListModal = true;

        let _this = this;
        setTimeout(() => {
          if (_this.$refs.itemLotCCreationListQuantityPerVolumeInput) {
            _this.$refs.itemLotCCreationListQuantityPerVolumeInput.$el.focus();
          }
        }, 500);
      },

      openItemLotSuggestionLocationBarcodeInput(index) {
        if(this.lotSuggestions[index].itemLot)
          this.itemLot = this.lotSuggestions[index].itemLot;
        else
          this.itemLot = null;

        this.selectedLotSuggestionIndex = index;
        this.lotSuggestionQuantity = this.lotSuggestions[index].usedQuantity;
        this.$bvModal.show('itemLot-suggestion-location-barcode-input-modal');
      },

      readItemLotSuggestionLocationBarcode(barcode, quantity) {
        if (!this.loadingItemLotSuggestionLocationBarcode) {
          this.lotSuggestionQuantity = Number(quantity);

          if (!isNaN(this.lotSuggestionQuantity) && this.lotSuggestionQuantity) {
            if (barcode && barcode.length) {
              this.loadingItemLotSuggestionLocationBarcode = true;
              httpClient.get(`${process.env.VUE_APP_API_URL}locations/barcode/${barcode}`)
              .then(data => {
                this.loadingItemLotSuggestionLocationBarcode = false;

                if (data.data.data) {
                  if (data.data.data.item && data.data.data.item.id !== this.selectItem.item.id) {
                    this.$notify.textError('Endereço não é compatível com este item');
                  } else if (this.order.stockLocale && data.data.data.stockLocale.id !== this.order.stockLocale.id) {
                    this.$notify.textError('Endereço não é compatível com este armazém');
                  } else {
                    this.selectItem.locals = [
                      data.data.data
                    ];
                    let selectItem = this.selectItem;
                    this.selectItem = {};
                    this.selectItem = selectItem;

                    if (this.itemLotSuggestionSelectedByBarcodeIndex > -1) {
                      this.selectedItemOrderItemLots[this.itemLotSuggestionSelectedByBarcodeIndex].usedQuantity = this.lotSuggestionQuantity;
                      this.selectedItemOrderItemLots[this.itemLotSuggestionSelectedByBarcodeIndex].location = data.data.data;
                      this.createItemLotMovementByOrderItemLot(this.itemLotSuggestionSelectedByBarcodeIndex);
                    } else {
                      this.selectedItemOrderItemLots[this.selectedLotSuggestionIndex].usedQuantity = this.lotSuggestionQuantity;
                      this.selectedItemOrderItemLots[this.selectedLotSuggestionIndex].location = data.data.data;
                    }

                    this.$bvModal.hide('itemLot-suggestion-location-barcode-input-modal');
                    this.$notify.success('Endereço encontrado');
                  }
                } else {
                  this.$notify.textError('Endereço não encontrado')
                }

                this.itemLotSuggestionLocationBarcodeAttempts++;
              }).catch(error => {
                this.loadingItemLotSuggestionLocationBarcode = false;
                if (error.message) {
                  this.$notify.error(error)
                } else {
                  this.$notify.textError('Houve um erro ao buscar o endereço pelo código de barras. Por favor tente novamente')
                }

                this.itemLotSuggestionLocationBarcodeAttempts++;
              });
            } else {
              this.$notify.textError('Código inválido. Por favor insira um código de barras válido')
            }
          } else {
            this.$notify.textError('Por favor informe a quantidade');
          }
        }

        this.itemLotSuggestionLocationBarcodeAttempts++;
      },

      createItemLotMovementByOrderItemLot(index) {
        this.selectedItemOrderItemLots[index].usedQuantity = Number(this.selectedItemOrderItemLots[index].usedQuantity);

        if (!this.selectedItemOrderItemLots[index].usedQuantity || isNaN(this.selectedItemOrderItemLots[index].usedQuantity)) {
          this.$notify.textError('Por favor informe a quantidade do movimento');
          return;
        }

        if (!this.isSelectedItemOrderItemLotUsedQuantityAvailable(index)) {
          this.$notify.textError('A quantidade informada não está disponível');
          return;
        }

        if (!this.selectedItemOrderItemLots[index].location.id) {
          this.selectedItemOrderItemLots[index].location = { id: this.selectedItemOrderItemLots[index].location };
        }

        this.savingMovement = true;
        httpClient.post(
          `${process.env.VUE_APP_API_URL}order-item-lots/item-lot-movement`, this.selectedItemOrderItemLots[index]
        ).then(data => {
          this.savingMovement = false;
          this.haveSelectedItemOrderItemLotsAtLeastOneBalance = true;
          this.selectItem.alocatedQuantity += this.selectedItemOrderItemLots[index].usedQuantity;
          this.$notify.success('Movimento do lote ' + this.selectedItemOrderItemLots[index].itemLot.lotNumber + ' salvo com sucesso');

          this.selectedItemOrderItemLots[index].alocatedQuantity = data.data.data.alocatedQuantity;
          this.selectedItemOrderItemLots[index].quantity = data.data.data.quantity;
          this.selectedItemOrderItemLots[index].location = undefined;
          this.selectedItemOrderItemLots[index] = this.setOrderItemLotUsedQuantity(this.selectedItemOrderItemLots[index]);

          if (this.selectItem.alocatedQuantity >= this.selectItem.quantity) {
            this.$bvModal.hide('item-lot-suggestion-movement-modal');
          } else if (this.itemLotSuggestionSelectedByBarcodeIndex > -1) {
            this.openItemLotSuggestionLotBarcodeInput();
          }

          this.resetItemLotSuggestionSelectedByBarcodeIndex();
        }).catch(error => {
          this.savingMovement = false;
          if (error.message) {
            this.$notify.error(error)
          } else {
            this.$notify.textError('Houve um erro ao salvar a movimentação do item ' + this.selectItem.item.description)
          }
        });
      },

      openItemLotSuggestionLotBarcodeInput() {
        this.$bvModal.show('itemLot-suggestion-lot-barcode-input-modal');
      },

      readItemLotSuggestionLotBarcode(barcode) {
        if (!this.loadingItemLotSuggestionLotBarcode) {
          if (barcode && barcode.length) {
            this.loadingItemLotSuggestionLotBarcode = true;
            httpClient.get(`${process.env.VUE_APP_API_URL}item-lots/barcode/${barcode}`)
            .then(data => {
              this.loadingItemLotSuggestionLotBarcode = false;

              if (data.data.data) {
                if (data.data.data.item.id !== this.selectItem.item.id) {
                  this.$notify.textError('Lote não é compatível com este item');
                } else {
                  for (let i = 0; i < this.selectedItemOrderItemLots.length; i++) {
                    let oil = this.selectedItemOrderItemLots[i];

                    if (oil.itemLot && oil.itemLot.id === data.data.data.id) {
                      if (oil.alocatedQuantity >= oil.quantity) {
                        this.$notify.textError('Os movimentos deste Lote já foram executados');
                        this.itemLotSuggestionLotBarcodeAttempts++;
                        return;
                      } else {
                        this.itemLotSuggestionSelectedByBarcodeIndex = i;
                        this.$bvModal.hide('itemLot-suggestion-lot-barcode-input-modal');
                        this.$notify.success('Lote encontrado');

                        if (oil.location && oil.location.id) {
                          this.createItemLotMovementByOrderItemLot(i);
                        } else {
                          this.openItemLotSuggestionLocationBarcodeInput(i);
                        }

                        return;
                      }
                    }
                  }

                  this.$notify.textError('Lote não está entre os lotes criados para esse item')
                }
              } else {
                this.$notify.textError('Lote não encontrado')
              }

              this.itemLotSuggestionLotBarcodeAttempts++;
            }).catch(error => {
              this.loadingItemLotSuggestionLotBarcode = false;
              if (error.message) {
                this.$notify.error(error)
              } else {
                this.$notify.textError('Houve um erro ao buscar o lote pelo código de barras. Por favor tente novamente')
              }

              this.itemLotSuggestionLotBarcodeAttempts++;
            });
          } else {
            this.$notify.textError('Código inválido. Por favor insira um código de barras válido')
          }
        }

        this.itemLotSuggestionLotBarcodeAttempts++;
      },

      resetItemLotSuggestionSelectedByBarcodeIndex() {
        this.itemLotSuggestionSelectedByBarcodeIndex = -1;
      },

      alwaysTrueFilterPredicate() {
        return true;
      },

      add(value1, value2) {
        return shared.add(value1, value2);
      },

      subtract(value1, value2) {
        return shared.subtract(value1, value2);
      },

      multiply(value1, value2) {
        return shared.multiply(value1, value2);
      },

      divide(value1, value2) {
        return shared.divide(value1, value2);
      },

      isSelectedItemOrderItemLotUsedQuantityAvailable(index) {
        return this.selectedItemOrderItemLots[index].usedQuantity <= (this.selectedItemOrderItemLots[index].quantity || 0) - (this.selectedItemOrderItemLots[index].alocatedQuantity || 0);
      },

      printOrderItemLotsBarcodes(method, orderItemLots, index) {
         this.barcodesPrintOrderItemLots = orderItemLots;
         this.barcodesPrintOrderItemLotsIndex = index;
         method();
      },

      openOrderFinishModal() {
        this.atLeastOneOrderItemUnfinished = false;

        for (let i = 0; i < this.order.orderItems.length; i++) {
          let orderItem = this.order.orderItems[0];

          if ((orderItem.alocatedQuantity || 0) < (orderItem.quantity || 0)) {
            this.atLeastOneOrderItemUnfinished = true;
            break;
          }
        }

        this.orderFinishModalShow = true;
      },

      openGeneralLocationLocationBarcodeInput() {
        this.$bvModal.show('general-location-barcode-input-modal');
      },

      readGeneralLocationBarcode(barcode) {
        if (!this.loadingGeneralLocationBarcode) {
          if (barcode && barcode.length) {
            this.loadingGeneralLocationBarcode = true;
            httpClient.get(`${process.env.VUE_APP_API_URL}locations/barcode/${barcode}`)
            .then(data => {
              this.loadingGeneralLocationBarcode = false;

              if (data.data.data) {
                if (data.data.data.item && data.data.data.item.id !== this.selectItem.item.id) {
                  this.$notify.textError('Endereço não é compatível com este item');
                } else if (this.order.stockLocale && data.data.data.stockLocale.id !== this.order.stockLocale.id) {
                  this.$notify.textError('Endereço não é compatível com este armazém');
                } else {
                  this.generalLocation = data.data.data;
                  this.$bvModal.hide('general-location-barcode-input-modal');
                  this.$notify.success('Endereço encontrado');
                }
              } else {
                this.$notify.textError('Endereço não encontrado')
              }

              this.generalLocationBarcodeAttempts++;
            }).catch(error => {
              this.loadingGeneralLocationBarcode = false;
              if (error.message) {
                this.$notify.error(error)
              } else {
                this.$notify.textError('Houve um erro ao buscar o endereço pelo código de barras. Por favor tente novamente')
              }

              this.generalLocationBarcodeAttempts++;
            });
          } else {
            this.$notify.textError('Código inválido. Por favor insira um código de barras válido')
          }
        }

        this.generalLocationBarcodeAttempts++;
      },

      openAllocateAllItemLotsToTheSameLocationModal() {
        this.showAllocateAllItemLotsToTheSameLocationModal = true;
      },

      allocateAllItemLotsToTheSameLocation() {
        this.selectedItemOrderItemLots.forEach(oil => {
          if ((oil.alocatedQuantity || 0) < (oil.quantity || 0)) {
            oil.location = this.generalLocation;

            if (oil.quantity) {
              oil.quantity = Number(oil.quantity);
            }

            if (oil.alocatedQuantity) {
              oil.alocatedQuantity = Number(oil.alocatedQuantity);
            }

            oil.usedQuantity = (oil.quantity || 0) - (oil.alocatedQuantity || 0);
          }
        });

        let orderItemLots = this.selectedItemOrderItemLots.filter(oil => (oil.alocatedQuantity || 0) < (oil.quantity || 0));

        this.savingMovement = true;
        httpClient.post(`${process.env.VUE_APP_API_URL}order-item-lots/item-lot-movement/multiple`, orderItemLots)
        .then(data => {
          this.$notify.success(
            'Movimentos do item ' + this.getItemReduzidoFromRef(this.selectItem.ref) + ' - ' + this.selectItem.description + ' salvos com sucesso'
          );
          this.processExecutedOrderItemLots(data.data.data);
        }).catch(error => {
          if (error.message) {
            this.$notify.error(error)
          } else {
            this.$notify.textError('Houve um erro ao buscar o endereço pelo código de barras. Por favor tente novamente')
          }
          this.processExecutedOrderItemLots(data.data.data);
        });
      },

      processExecutedOrderItemLots(executedOrderItemLots) {
        this.savingMovement = false;
        if (executedOrderItemLots && executedOrderItemLots.length) {
          this.haveSelectedItemOrderItemLotsAtLeastOneBalance = true;
          this.$notify.success('Foram executados ' + executedOrderItemLots.length + ' movimentos');

          executedOrderItemLots.forEach(oil => {
            let index = this.selectedItemOrderItemLots.findIndex(sioil => sioil.id === oil.id);

            if (index > -1) {
              this.selectItem.alocatedQuantity += this.selectedItemOrderItemLots[index].usedQuantity;

              let location = this.selectedItemOrderItemLots[index].location;
              this.selectedItemOrderItemLots[index] = oil;
              this.selectedItemOrderItemLots[index].location = location;
              this.selectedItemOrderItemLots[index] = this.setOrderItemLotUsedQuantity(this.selectedItemOrderItemLots[index]);
            }
          });

          if (this.selectItem.alocatedQuantity >= this.selectItem.quantity) {
            this.$bvModal.hide('allocate-all-item-lots-to-the-same-location-modal');
            this.$bvModal.hide('item-lot-suggestion-movement-modal');
          }
        } else {
          this.$notify.textError('Nenhum movimento executado');
        }
      },

      setOrderItemLotUsedQuantity(orderItemLot) {
        if (orderItemLot.itemLot) {
          if(orderItemLot.itemLot.quantityPerVolume){
            orderItemLot.usedQuantity = orderItemLot.itemLot.quantityPerVolume;
            orderItemLot.usedNumberOfVolumes = 1;
          } else {
            orderItemLot.usedQuantity = 0;
          }
        }

        let remainingQuantity = (orderItemLot.quantity || 0) - (orderItemLot.alocatedQuantity || 0);

        if (orderItemLot.usedQuantity === undefined || orderItemLot.usedQuantity === null || orderItemLot.usedQuantity > remainingQuantity) {
          orderItemLot.usedQuantity = remainingQuantity;
        }

        return orderItemLot;
      },

      orderItemSaved(orderItem) {
        let index = this.order.orderItems.findIndex(oi => oi.id === orderItem.id);

        if (index === -1) {
          index = this.order.orderItems.length;
        }

        this.order.orderItems[index] = orderItem;
        let orderItems = this.order.orderItems;
        this.order.orderItems = [];
        this.order.orderItems = orderItems;
      },

      openOrderItemEditModal(orderItem) {
        this.$refs.orderItemFormModal.open(orderItem);
      },

      openOrderItemContextMenu($event, orderItem, index) {
        if (!this.shouldFinishOrderAutomatically()) {
          const ctxMenuData = [
            {
              title: "Editar",
              handler: this.openOrderItemEditModal.bind(this, orderItem)
            },
            {
              title: "Excluir",
              handler: this.openOrderItemDeleteModal.bind(this, index)
            }
          ];

          this.$root.$emit("contextmenu", { event, ctxMenuData });
        }
      },

      shouldFinishOrderAutomatically() {
        return this.order.system !== 'C' && (
          !this.order.transformationMS || this.order.transformationMS.system !== 'C'
        );
      },

      openOrderItemDeleteModal(index) {
        this.deleteIndex = index;
        this.$bvModal.show('order-item-delete-confirmation-modal');
      },

      deleteOrderItem() {
        this.savingOrder = true;
        httpClient.delete(`${process.env.VUE_APP_API_URL}order-items`, this.order.orderItems[this.deleteIndex].id)
        .then(({ data }) => {
          this.savingOrder = false;
          this.$notify.success('Item excluído com sucesso');
          this.order.orderItems.splice(this.deleteIndex, 1);
        })
        .catch((error) => {
          this.savingOrder = false;
          if (error.message) {
            this.$notify.error(error)
          } else {
            this.$notify.textError('Houve um erro ao excluir o item');
          }
        });
      },

      setUsedQuantityByUsedNumberOfVolumes(orderItemLot) {
        if(orderItemLot.itemLot && orderItemLot.usedNumberOfVolumes && orderItemLot.itemLot.quantityPerVolume) {
          orderItemLot.usedQuantity = orderItemLot.itemLot.quantityPerVolume * orderItemLot.usedNumberOfVolumes
          this.reloadOrder();
        }
      },

      anyOrderItemLotsWithoutQuantityPerVolume(currentOrderItemOrderItemLots) {
        if (currentOrderItemOrderItemLots) {
          for (let oil of currentOrderItemOrderItemLots) {
            if (oil.itemLot && !oil.itemLot.quantityPerVolume) {
              return true;
            }
          }
        }

        return false;
      },

      saveSelectedItemOrderItemLotsQuantityPerVolume() {
        this.$bvModal.show('save-quantity-per-volume-confirmation-modal');
      },

      confirmSaveSelectedItemOrderItemLotsQuantityPerVolume() {
        this.selectedItemOrderItemLots.forEach(oil => {
          if (oil.itemLot && oil.itemLot.quantityPerVolume) {
            oil.itemLot.quantityPerVolume = Number(oil.itemLot.quantityPerVolume.replace(',', '.'));
          }
        });

        let temp = this.selectedItemOrderItemLots.filter(oil => oil.itemLot);
        if (!temp.length) {
          this.$notify.textError('Erro: lotes não preenchidos');
          return;
        }

        this.creatingItemLotSuggestions = true;
        let body = temp.map(oil => {
          return {
            id: oil.id,
            itemLot: {
              id: oil.itemLot.id,
              quantityPerVolume: oil.itemLot.quantityPerVolume
            }
          }
        });
        httpClient.put(`${process.env.VUE_APP_API_URL}order-item-lots/multiple?patch=true`, body).then(data => {
          this.creatingItemLotSuggestions = false;
          this.$notify.success('Quantidades por volume salvas com sucesso');

          if (data.data.data) {
            data.data.data.forEach(oil => {
              let index = this.orderItemLots.findIndex(o => o.id === oil.id && o.itemLot);

              if (index > -1) {
                this.orderItemLots[index].itemLot.quantityPerVolume = oil.itemLot.quantityPerVolume;
              }
            });
          }

          this.selectItem.anyOrderItemLotsWithoutQuantityPerVolume = this.anyOrderItemLotsWithoutQuantityPerVolume(this.selectedItemOrderItemLots);
          this.showQuantityPerVolumeModal = false;
        }).catch(error => {
          this.creatingItemLotSuggestions = false;
          if (error.message) {
            this.$notify.error(error)
          } else {
            this.$notify.textError('Houve um erro ao salvar a quantidade por volume')
          }
        });
      },

      quantityPerVolumeFillInputEnterPressed() {
        if (this.anyOrderItemLotsWithoutQuantityPerVolume(this.selectedItemOrderItemLots)) {
          this.openQuantityPerVolumeItemLotBarcodeInputModal();
        } else {
          this.saveSelectedItemOrderItemLotsQuantityPerVolume();
        }
      },

      openQuantityPerVolumeItemLotBarcodeInputModal() {
        this.$bvModal.show('quantity-per-volume-barcode-input-modal');
      },

      readQuantityPerVolumeBarcode(barcode) {
        if (!this.loadingQuantityPerVolumeBarcode) {
          if (barcode && barcode.length) {
            this.loadingQuantityPerVolumeBarcode = true;
            httpClient.get(`${process.env.VUE_APP_API_URL}item-lots/barcode/${barcode}`)
            .then(data => {
              this.loadingQuantityPerVolumeBarcode = false;

              if (data.data.data) {
                if (data.data.data.item.id !== this.selectItem.item.id) {
                  this.$notify.textError('Lote não é compatível com este item');
                } else {
                  for (let i = 0; i < this.selectedItemOrderItemLots.length; i++) {
                    let oil = this.selectedItemOrderItemLots[i];

                    if (oil.itemLot && oil.itemLot.id === data.data.data.id) {
                      this.$bvModal.hide('quantity-per-volume-barcode-input-modal');
                      this.$notify.success('Lote encontrado');
                      let ref = 'quantityPerVolumeFillInput-' + i;

                      if (this.$refs[ref] && this.$refs[ref].length) {
                        setTimeout(() => {
                          this.$refs[ref][0].$el.focus();
                        }, 500);
                      }

                      return;
                    }
                  }

                  this.$notify.textError('Lote não está entre os lotes desse item')
                }
              } else {
                this.$notify.textError('Lote não encontrado')
              }

              this.quantityPerVolumeBarcodeAttempts++;
            }).catch(error => {
              this.loadingQuantityPerVolumeBarcode = false;
              if (error.message) {
                this.$notify.error(error)
              } else {
                this.$notify.textError('Houve um erro ao buscar o lote pelo código de barras. Por favor tente novamente')
              }

              this.quantityPerVolumeBarcodeAttempts++;
            });
          } else {
            this.$notify.textError('Código inválido. Por favor insira um código de barras válido')
          }
        }

        this.quantityPerVolumeBarcodeAttempts++;
      },

      openAllocationSuggestionsModal() {
        this.$refs.allocationSuggestionsModal.showModal();

        if (!this.wholeOrderItemLotMovements || !this.wholeOrderItemLotMovements.length) {
          this.loadWholeOrderNextItemLotMovements();
        }
      },

      loadWholeOrderNextItemLotMovements() {
        orderUtils.loadWholeOrderNextItemLotMovements(this);
      },

      updateWholeOrderItemLotMovements(ilms) {
        this.wholeOrderItemLotMovements = ilms;
      }
    },

    watch: {
      manualSelectionNumberOfVolumes(newValue, oldValue) {
        if(newValue && this.selectedLot && this.selectedLot.quantityPerVolume && newValue) {
          this.$set(this, 'quantity', this.selectedLot.quantityPerVolume * newValue);
        }
      }
    }
  }
</script>

<style>
  .v-dialog:target .v-dialog-overlay,
  .v-dialog.active .v-dialog-overlay {
    background: rgba(51, 58, 64, 0.75) !important;
  }

  .margin-right {
    margin-right: .8rem;
  }

  .pointer {
    cursor: pointer;
  }
</style>
