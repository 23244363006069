<template>
  <div>

    <div v-if="suggestions && suggestions.length" class="mx-n3">
      <div v-for="(suggestion, suggestionIndex) in suggestions" :key="suggestionIndex"
        :class="{ 'bg-light': suggestionIndex % 2 != 0 }" class="border py-3">
        <div class="row ml-n2" v-if="isFoundOils || !isSuggestionInFoundOils(suggestion)">

          <div class="col mb-1" v-if="suggestion.oil && suggestion.oil.orderItem && suggestion.oil.orderItem.item">
            <div class="row">

              <div class="col-auto img-fix-size-suggestions px-sm-2 cursor-pointer"
                v-if="suggestion.oil.orderItem.item.images.length"
                @click.prevent="openImagemModal(suggestion.oil.orderItem.item.images)">
                <div class="pl-1">
                  <img :src="suggestion.oil.orderItem.item.images[0].url" width="100%">
                </div>
              </div>

              <div class="col">
                <div class="row">

                  <div class="col-12 col-sm pb-xl-0">
                    <span class="font-weight-bold">Item: </span>
                    {{getItemReduzidoFromRef(suggestion.oil.orderItem.item.ref) + ' - ' + suggestion.oil.orderItem.item.description}}
                  </div>
                  <div class="col-12 col-lg-4">
                    <div class="row justify-content-between">

                      <div class="col d-none d-lg-block"></div>
                      <div class="col-auto">
                        <small class="font-weight-bold text-muted mr-lg-3 mr-0">Alocado / Total: </small>
                      </div>
                      <div class="col-12 d-none d-lg-block"></div>
                      <div class="col d-none d-lg-block"></div>
                      <div class="col-auto">
                        <composite-item-lot-quantity
                          class="mr-3"
                          :quantity="suggestion.oil.quantity"
                          :movedQuantity="suggestion.oil.alocatedQuantity || 0"
                          :itemLot="suggestion.oil.itemLot"
                        ></composite-item-lot-quantity>
                      </div>

                    </div>
                  </div>

                  <div class="col-12">
                    <div class="row">

                      <div v-if="!isSuggestionAutomaticLot(suggestion)" class="col-auto">
                        <span class="font-weight-bold">Número do Lote: </span>
                        <h5 class="font-weight-bold">
                          {{ suggestion.oil && suggestion.oil.itemLot ? suggestion.oil.itemLot.lotNumber : '' }}
                        </h5>
                      </div>

                      <div class="col">
                        <div class="row text-muted">
                          <small v-if="suggestion.oil.orderItem.item.sku" class="col-12">
                            <span class="font-weight-bold">Referência: </span>
                            {{suggestion.oil.orderItem.item.sku }}
                          </small>
                          <small v-if="suggestion.oil.orderItem.item.gtin" class="col-12">
                            <span class="font-weight-bold">Cód. barras: </span>
                            {{suggestion.oil.orderItem.item.gtin }}
                          </small>
                        </div>
                      </div>

                    </div>
                  </div>

                </div>
              </div>

            </div>
          </div>

          <div v-if="!suggestion.results || !suggestion.results.length">
            Esse lote não possui nenhuma sugestão
          </div>

          <div class="col-12">

            <div v-for="(result, resultIndex) in suggestion.results" :key="resultIndex" class="row mx-0 align-items-center border p-1 pt-2">

              <div v-if="result.location" class="col-lg col-12">
                <span class="font-weight-bold">Endereço: </span>
                <h5 class="font-weight-bold">
                  {{ result.location.name }}
                </h5>
              </div>

              <div v-if="suggestion.oil" class="col-lg-auto col">
                <small class="font-weight-bold text-muted">Qtd. Restante: </small><br>
                <composite-item-lot-quantity
                  :quantity="result.quantity"
                  :itemLot="suggestion.oil.itemLot"
                ></composite-item-lot-quantity>
              </div>

              <div class="col-auto">
                <div v-if="saving" class="col">
                  <animation />
                </div>
                <b-button class="float-md-right" v-if="!saving" variant="primary" :disabled="result.quantity <= 0"
                  @click.prevent="innerCheckClicked(suggestionIndex, resultIndex)">
                  <i class="fa fa-check"></i>
                </b-button>
              </div>

            </div>

          </div>

        </div>
      </div>
    </div>

    <image-modal
      ref="imageModal"
      title="Imagens do item"
      :images="itemImages"
    ></image-modal>

  </div>
</template>

<script>
import ImageModal from '@/components/common/image-modal';
import shared from '@/shared/shared';
import CompositeItemLotQuantity from '@/components/common/composite-item-lot-quantity';
import Animation from "@/components/loaders/animation";

export default {
  name: 'allocation-suggestions-table',
  components: {
    Animation,
    ImageModal,
    CompositeItemLotQuantity,
  },

  data () {
    return {
      itemImages: [],
    }
  },

  props: {
    isFoundOils: {
      type: Boolean
    },
    suggestions: {
      type: Array
    },
    isSuggestionAutomaticLot: {
      type: Function
    },
    saving: {
      type: Boolean
    },
    checkClicked: {
      type: Function
    },
    foundOils: {
      type: Array
    }
  },

  methods: {
    openImagemModal(images) {
      if (images.length) {
        this.itemImages = images;
        this.$refs.imageModal.showModal();
      }
    },

    getItemReduzidoFromRef(ref) {
      return shared.getItemReduzidoFromRef(ref);
    },

    innerCheckClicked(suggestionIndex, resultIndex) {
      this.checkClicked(suggestionIndex, resultIndex, this.isFoundOils);
    },

    isSuggestionInFoundOils(suggestion) {
      return this.foundOils && this.foundOils.find(b => b.oil.id === suggestion.oil.id);
    }
  }
}
</script>
