<template>
  <b-modal
    size="xl"
    centered
    lazy
    title="Buscar sugestões de alocação"
    header-bg-variant="info"
    footer-border-variant="info"
    v-model="show"
    @hidden="onHidden"
    :hide-footer="true"
  >
    <b-container>
      <div v-if="loading" class="row justify-content-center">
        <div class="col-auto">
          <animation />
        </div>
      </div>

      <div v-else>
        <div class="card mb-0 mx-n3">
          <div class="card-body py-2 p-sm-3 px-0">
            <div class="row mx-md-n3 mx-0 align-items-end">
              <div class="col-xl-3 col-sm-6 col-12">
                <div v-if="loadingSuggestionLotBarcode" class="mb-2 mx-5">
                  <animation />
                </div>
                <div v-else>
                  <b-form-group
                    label="Cód. de barras do Lote/Item"
                    label-for="selectSuggestionByBarcodeItemLotBarcodeInput"
                    class="mb-0"
                  >
                    <b-button
                      v-if="useCamera"
                      variant="secondary"
                      @click.prevent="openHeaderItemLotBarcodeInputModal()"
                    >
                      <i class="fa fa-barcode"></i>
                    </b-button>
                    <b-form-input
                      v-else
                      id="selectSuggestionByBarcodeItemLotBarcodeInput"
                      v-model="selectSuggestionByBarcodeItemLotBarcode"
                      @keyup.enter="headerItemLotInputBeeped()"
                      ref="selectSuggestionByBarcodeItemLotBarcodeInput"
                    />
                  </b-form-group>
                </div>

              </div>
              <div class="col-xl-3 col-sm-6 col-12 mt-sm-0 mt-3">
                <div v-if="loadingSuggestionLocationBarcode" class="mb-2 mx-5">
                  <animation />
                </div>
                <b-form-group v-else label="Cód. de barras do Endereço" label-for="selectSuggestionByBarcodeLocationBarcodeInput" class="mb-0">
                  <b-button
                    v-if="useCamera"
                    variant="secondary"
                    :disabled="headerLocationBarcodeInputDisabled"
                    @click.prevent="openHeaderLocationBarcodeInputModal()"
                  >
                    <i class="fa fa-barcode"></i>
                  </b-button>
                  <b-form-input v-else id="selectSuggestionByBarcodeLocationBarcodeInput" v-model="selectSuggestionByBarcodeLocationBarcode"
                    @keyup.enter="headerLocationInputBeeped()"
                    :disabled="headerLocationBarcodeInputDisabled"
                    ref="selectSuggestionByBarcodeLocationBarcodeInput"/>
                </b-form-group>
                <b-form-checkbox v-if="previousLocation"
                  :disabled="Boolean(foundOils && foundOils.length)" v-model="usePreviousLocation" class="pt-1">
                  Usar endereço anterior
                </b-form-checkbox>
              </div>
              <div class="col-xl-6 col-12 mt-xl-0 mt-3">
                <div class="row align-items-end">
                  <div class="col-sm-4 col-12 pt-3">
                    <b-button variant="secondary" @click.prevent="searchSuggestions" :disabled="loading" class="w-100">
                      Recarregar
                    </b-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <h6 class="font-weight-bold mt-4">Sugestões encontradas:</h6>

        <div v-if="foundOils && foundOils.length">
          <allocation-suggestions-table
            :isFoundOils="true"
            :suggestions="foundOils"
            :foundOils="foundOils"
            :isSuggestionAutomaticLot="isSuggestionAutomaticLot"
            :saving="saving"
            :checkClicked="checkClicked"
          ></allocation-suggestions-table>
        </div>

        <h6 class="font-weight-bold mt-4">Sugestões de endereços:</h6>

        <allocation-suggestions-table
          :isFoundOils="false"
          :suggestions="suggestions"
          :foundOils="foundOils"
          :isSuggestionAutomaticLot="isSuggestionAutomaticLot"
          :saving="saving"
          :checkClicked="checkClicked"
        ></allocation-suggestions-table>

        <div v-if="!suggestions || !suggestions.length" class="mb-3">
          <div class="row bg-light py-3">
            <div class="col text-center">
              Nenhuma sugestão encontrada
            </div>
          </div>
        </div>

        <h6 class="font-weight-bold mt-4">Movimentos lançados:</h6>

        <finished-movements-table
          :loadingItemLotMovements="loadingItemLotMovements"
          :itemLotMovements="itemLotMovements"
          :foundBalances="foundOils"
          :suggestionsLotPrefixes="[]"
          :isItemLotMovementsLastPage="isItemLotMovementsLastPage"
          :loadNextItemLotMovements="loadNextItemLotMovements"
        ></finished-movements-table>

      </div>

      <barcode-input-modal
        id="lot-barcode-confirmation-modal"
        :title="'Confirme o código de barras do ' + (isBeepItemAutomaticLot ? 'Item' : 'Lote')"
        :isEntrance="true"
        :attempts="lotConfirmationBarcodeAttempts"
        :ok="confirmLotBarcode"
        :config="config"
        :authUser="authUser"
        :parameter="parameter"
      ></barcode-input-modal>
      <barcode-input-modal
        id="location-barcode-confirmation-modal"
        title="Confirme o código de barras do Endereço"
        :isEntrance="true"
        :attempts="locationConfirmationBarcodeAttempts"
        :ok="confirmLocationBarcode"
        :config="config"
        :authUser="authUser"
        :parameter="parameter"
      ></barcode-input-modal>

      <barcode-input-modal
        id="header-item-lot-barcode-input-modal"
        :loading="loadingSuggestionLotBarcode"
        :ok="headerItemLotInputBeeped"
        :config="config"
        :authUser="authUser"
        :parameter="parameter"
      ></barcode-input-modal>

      <barcode-input-modal
        id="header-location-barcode-input-modal"
        :loading="loadingSuggestionLocationBarcode"
        :ok="headerLocationInputBeeped"
        :config="config"
        :authUser="authUser"
        :parameter="parameter"
      ></barcode-input-modal>

    </b-container>
  </b-modal>
</template>

<script>
import Animation from "@/components/loaders/animation";
import { httpClient } from "@/service";
import shared from '@/shared/shared';
import BarcodeInputModal from '@/components/common/barcode-input-modal';
import FinishedMovementsTable from '@/components/tables/finished-movements-table'
import AllocationSuggestionsTable from '@/components/tables/allocation-suggestions-table';
import orderUtils from '@/shared/order-utils'

export default {
  name: 'allocation-suggestions-modal',
  components: {
    Animation,
    BarcodeInputModal,
    FinishedMovementsTable,
    AllocationSuggestionsTable
  },

  props: {
    order: {
      type: Object,
      require
    },
    onHidden: {
      type: Function
    },
    loadNextItemLotMovements: {
      type: Function
    },
    loadingItemLotMovements: {
      type: Boolean
    },
    itemLotMovements: {
      type: Array
    },
    updateItemLotMovements: {
      type: Function
    },
    isItemLotMovementsLastPage: {
      type: Boolean
    },
    config: {
      type: Object
    },
    authUser: {
      type: Object
    },
    parameter: {
      type: Object
    }
  },

  data() {
    return {
      show: false,
      loading: false,
      suggestions: [],
      saving: false,
      currentSuggestionIndex: -1,
      currentResultIndex: -1,
      isBeepItemAutomaticLot: false,
      lotConfirmationBarcodeAttempts: 0,
      locationConfirmationBarcodeAttempts: 0,
      loadingSuggestionLotBarcode: false,
      loadingSuggestionLocationBarcode: false,
      selectSuggestionByBarcodeItemLotBarcode: null,
      selectSuggestionByBarcodeLocationBarcode: null,
      usePreviousLocation: false,
      previousLocation: null,
      foundOils: [],
      isBeepItemFromFoundOils: false
    }
  },

  computed: {
    currentSuggestion() {
      if (this.currentSuggestionIndex > -1) {
        if (this.isBeepItemFromFoundOils) {
          return this.foundOils[this.currentSuggestionIndex];
        } else {
          return this.suggestions[this.currentSuggestionIndex];
        }
      }
    },

    currentResult() {
      if (this.currentResultIndex > -1 && this.currentSuggestion && this.currentSuggestion.results) {
        return this.currentSuggestion.results[this.currentResultIndex];
      }
    },

    useCamera() {
      return shared.useCamera(this.parameter, this.config);
    },

    autoOpenCamera() {
      return shared.autoOpenCamera(this.parameter, this.config);
    },

    headerLocationBarcodeInputDisabled() {
      return !this.foundOils || !this.foundOils.length || this.usePreviousLocation;
    }
  },

  methods: {
    showModal(){
      this.show = true;
      this.searchSuggestions();
    },

    hideModal() {
      this.show = false;
    },

    searchSuggestions() {
      this.resetFoundOils();
      this.loading = true;
      httpClient.get(`${process.env.VUE_APP_API_URL}orders/allocation-suggestions/${this.order.id}`).then(data => {
        this.loading = false;
        this.suggestions = data.data.data;

        if (this.suggestions) {
          this.suggestions.forEach(d => {
            if (d.oil) {
              if (d.oil.itemLot && d.oil.itemLot.createdAt) {
                d.formattedDate = shared.formatDate(d.oil.itemLot.createdAt);
              }
            }
          });
        }

        this.focusOnSuggestionItemLotBarcodeInput();
      }).catch(error => {
        this.loading = false;
        if (error.message) {
          this.$notify.error(error)
        } else {
          this.$notify.textError('Houve um erro ao criar o lote de um dos itens')
        }
      });
    },

    isSuggestionAutomaticLot(suggestion) {
      return suggestion.oil && suggestion.oil.orderItem && suggestion.oil.orderItem.item && suggestion.oil.orderItem.item.automaticItemLot;
    },

    suggestionHasQtdPVolume(suggestion) {
      return suggestion.oil && suggestion.oil.itemLot && suggestion.oil.itemLot.quantityPerVolume;
    },

    checkClicked(suggestionIndex, resultIndex, isFromFoundOils) {
      this.currentSuggestionIndex = suggestionIndex;
      this.currentResultIndex = resultIndex;
      this.isBeepItemFromFoundOils = isFromFoundOils;

      if (this.currentSuggestion && this.currentResult) {
        this.isBeepItemAutomaticLot = this.isSuggestionAutomaticLot(this.currentSuggestion);

        if (!this.isBeepItemFromFoundOils || this.isBeepItemAutomaticLot) {
          this.openLotConfirmationModal();
        } else {
          this.openLocationConfirmationModal();
        }
      }
    },

    openLotConfirmationModal() {
      this.$bvModal.show('lot-barcode-confirmation-modal');
    },

    confirmLotBarcode(barcode) {
      if (barcode && barcode.length) {
        if (
          (!this.isBeepItemAutomaticLot && barcode === this.currentSuggestion.oil.itemLot.barcode)
          || (this.isBeepItemAutomaticLot && barcode === this.currentSuggestion.oil.orderItem.item.gtin)
        ) {
          this.$notify.success((this.isBeepItemAutomaticLot ? 'Item' : 'Lote') + ' confirmado')
          this.$bvModal.hide('lot-barcode-confirmation-modal');
          this.openLocationConfirmationModal();
        } else {
          this.$notify.textError('O código de barras inserido não é o mesmo do ' + (this.isBeepItemAutomaticLot ? 'item' : 'lote') + ' selecionado');
        }
      } else {
        this.$notify.textError('Código inválido. Por favor insira um código de barras válido')
      }

      this.lotConfirmationBarcodeAttempts++;
    },

    openLocationConfirmationModal() {
      //Na separção precisa pois ele faz direto o movimento nesse caso
      //Mas na alocação não
      /*if (this.isBeepItemFromFoundBalances) {
        this.isBeepItemFromFoundBalances = false;
      }*/

      this.$bvModal.show('location-barcode-confirmation-modal');
    },

    confirmLocationBarcode(barcode) {
      if (barcode && barcode.length) {
        if (barcode === this.currentResult.location.barcode) {
          this.$notify.success('Endereço confirmado')
          this.$bvModal.hide('location-barcode-confirmation-modal');
          this.saveMovement();
        } else {
          this.$notify.textError('O código de barras inserido não é o mesmo do endereço selecionado');
        }
      } else {
        this.$notify.textError('Código inválido. Por favor insira um código de barras válido')
      }

      this.locationConfirmationBarcodeAttempts++;
    },

    saveMovement() {
      let quantity = 1;
      if (this.currentSuggestion.oil.itemLot && this.currentSuggestion.oil.itemLot.quantityPerVolume) {
        quantity = 1 * this.currentSuggestion.oil.itemLot.quantityPerVolume;
      }

      //Para testes
      //quantity = 0.01;

      let oilRemainingQuantity = this.currentSuggestion.oil.quantity - (this.currentSuggestion.oil.alocatedQuantity || 0);
      quantity = Math.min(quantity, oilRemainingQuantity);
      quantity = Math.min(quantity, this.currentResult.quantity);

      let location = this.currentResult.location;
      let itemLot = this.currentSuggestion.oil.itemLot;
      let orderItem = this.currentSuggestion.oil.orderItem;

      if (!quantity || isNaN(quantity)) {
        this.$notify.textError('Por favor informe a quantidade do movimento');
        return;
      }

      let body = {
        quantity: quantity,
        orderItem: orderItem,
        itemLot: itemLot,
        location: location,
        companyGroup: this.currentSuggestion.oil.orderItem.item.companyGroup
      };

      this.saving = true;
      httpClient.post(`${process.env.VUE_APP_API_URL}item-lot-movements/`, body).then(data => {
        this.saving = false;
        this.$notify.success('Movimento salvo com sucesso');
        this.currentSuggestion.oil.alocatedQuantity = (this.currentSuggestion.oil.alocatedQuantity || 0) + quantity;
        this.currentResult.quantity -= quantity;

        for (let i = 0; i < this.order.orderItems.length; i++) {
          if (this.order.orderItems[i].id === data.data.data.orderItem.id) {
            this.order.orderItems[i].alocatedQuantity = data.data.data.orderItem.alocatedQuantity;
            break;
          }
        }

        this.removeFinishedSuggestions();
        let newItemLotMovements = orderUtils.getItemLotMovementsFromMovementResponse(data);
        if (newItemLotMovements && newItemLotMovements.length) {
          let temp = this.itemLotMovements;
          temp.unshift(...newItemLotMovements);
          this.updateItemLotMovements(temp);
        }

        if (this.suggestions.length) {
          this.resetFoundOils();
          this.focusOnSuggestionItemLotBarcodeInput();
        } else {
          //Todos os oils foram alocados
          this.hideModal();
        }

        this.isBeepItemFromFoundOils = false;
      }).catch(error => {
        this.saving = false;
        if (error.message) {
          this.$notify.error(error)
        } else {
          this.$notify.textError('Houve um erro ao salvar a movimentação')
        }
      });
    },

    openHeaderItemLotBarcodeInputModal() {
      this.$bvModal.show("header-item-lot-barcode-input-modal");
    },

    headerItemLotInputBeeped(barcode) {
      if (barcode !== undefined) {
        this.selectSuggestionByBarcodeItemLotBarcode = barcode;
        this.$bvModal.hide("header-item-lot-barcode-input-modal");
      }

      this.readSuggestionLotBarcode();
    },

    openHeaderLocationBarcodeInputModal() {
      this.$bvModal.show("header-location-barcode-input-modal");
    },

    headerLocationInputBeeped(barcode) {
      if (barcode !== undefined) {
        this.selectSuggestionByBarcodeLocationBarcode = barcode;
        this.$bvModal.hide("header-location-barcode-input-modal");
      }

      this.readSuggestionLocationBarcode();
    },

    readSuggestionLotBarcode() {
      this.resetFoundOils();
      let barcode = this.selectSuggestionByBarcodeItemLotBarcode;

      if (!this.loadingSuggestionLotBarcode) {
        if (barcode && barcode.length) {
          this.loadingSuggestionLotBarcode = true;
          httpClient.get(`${process.env.VUE_APP_API_URL}item-lots/barcode/${barcode}`)
          .then(data => {
            this.loadingSuggestionLotBarcode = false;

            if (data.data.data) {
              let hasItemLotItemAutomaticItemLot = data.data.data.item && data.data.data.item.automaticItemLot;
              let foundSuggestions = [];

              this.suggestions.forEach(s => {
                if (
                  (hasItemLotItemAutomaticItemLot && s.oil && s.oil.orderItem && s.oil.orderItem.item && s.oil.orderItem.item.id === data.data.data.item.id)
                  || (!hasItemLotItemAutomaticItemLot && s.oil && s.oil.itemLot && s.oil.itemLot.id === data.data.data.id)
                ) {
                  foundSuggestions.push(s);
                }
              });

              if (foundSuggestions.length) {
                this.foundOils = foundSuggestions;

                /*if (!hasItemLotItemAutomaticItemLot) {
                  this.setSuggestionsLotPrefixes();
                }*/

                //this.setSuggestionInitialQuantity(data.data.data);
                this.$notify.success('Sugestões encontradas');

                if (this.usePreviousLocation && this.previousLocation) {
                  if (this.processSuggestionFoundLocation(this.previousLocation)) {
                    return
                  }
                } else {
                  setTimeout(() => {
                    this.focusOnSuggestionLocationBarcodeInput();
                  }, 1);

                  return;
                }
              }

              this.$notify.textError((hasItemLotItemAutomaticItemLot ? 'Item' : 'Lote') + ' não está entre as sugestões');
            } else {
              this.$notify.textError('Lote/Item não encontrado')
            }

            this.focusOnSuggestionItemLotBarcodeInput();
          }).catch(error => {
            this.loadingSuggestionLotBarcode = false;
            if (error.message) {
              this.$notify.error(error)
            } else {
              this.$notify.textError('Houve um erro ao buscar o lote pelo código de barras. Por favor tente novamente')
            }

            this.focusOnSuggestionItemLotBarcodeInput();
          });
        } else {
          this.$notify.textError('Código inválido. Por favor insira um código de barras válido')
        }
      }
    },

    readSuggestionLocationBarcode(headerInputValues) {
      let barcode = this.selectSuggestionByBarcodeLocationBarcode;

      if (!this.loadingSuggestionLocationBarcode) {
        if (barcode && barcode.length) {
          this.loadingSuggestionLocationBarcode = true;
          httpClient.get(`${process.env.VUE_APP_API_URL}locations/barcode/${barcode}`)
          .then(data => {
            this.loadingSuggestionLocationBarcode = false;

            let ok = false;
            if (data.data.data) {
              ok = this.processSuggestionFoundLocation(data.data.data, headerInputValues);
            } else {
              this.$notify.textError('Endereço não encontrado')
            }

            if (!ok)
              this.focusOnSuggestionLocationBarcodeInput();

          }).catch(error => {
            this.loadingSuggestionLocationBarcode = false;
            if (error.message) {
              this.$notify.error(error)
            } else {
              this.$notify.textError('Houve um erro ao buscar o endereço pelo código de barras. Por favor tente novamente')
            }

            this.focusOnSuggestionLocationBarcodeInput();
          });
        } else {
          this.$notify.textError('Código inválido. Por favor insira um código de barras válido')
        }
      }
    },

    resetFoundOils() {
      this.foundOils = [];
    },

    focusOnSuggestionItemLotBarcodeInput() {
      setTimeout(() => {
        this.selectSuggestionByBarcodeItemLotBarcode = null;

        if (!this.usePreviousLocation)
          this.selectSuggestionByBarcodeLocationBarcode = null;

        if (this.useCamera && this.autoOpenCamera) {
          this.openHeaderItemLotBarcodeInputModal();
        } else if (this.$refs.selectSuggestionByBarcodeItemLotBarcodeInput) {
          this.$refs.selectSuggestionByBarcodeItemLotBarcodeInput.$el.focus();
        }
      }, 2);
    },

    focusOnSuggestionLocationBarcodeInput() {
      if (this.useCamera && this.autoOpenCamera) {
        this.openHeaderLocationBarcodeInputModal();
      } else if (this.$refs.selectSuggestionByBarcodeLocationBarcodeInput) {
        this.$refs.selectSuggestionByBarcodeLocationBarcodeInput.$el.focus();
      }
    },

    processSuggestionFoundLocation(location) {
      if (this.order.stockLocale && location.stockLocale.id !== this.order.stockLocale.id) {
        this.$notify.textError('Endereço não é compatível com este armazém');
      /*} else if (this.selectItem && this.selectItem.item && this.selectItem.item.automaticItemLot) {
        this.foundBalances = this.selectItem.suggestions.filter(b => b.location && b.location.id === location.id);

        if (this.foundBalances && this.foundBalances.length) {
          this.updateSelectedItemLocals([location]);
          this.$notify.success('Endereço encontrado');

          setTimeout(() => {
            if (this.foundBalances.length === 1) {
              this.$refs.selectItemPrepareSuggestionsModal.clickOnCheck(this.foundBalances[0]);
              this.$refs.wholeOrderPrepareSuggestionsModal.clickOnCheck(this.foundBalances[0]);
            } else {
              this.$refs.selectItemPrepareSuggestionsModal.focusOnFoundBalancesQuantityField();
              this.$refs.wholeOrderPrepareSuggestionsModal.focusOnFoundBalancesQuantityField();
            }
          }, 2);

          return true;
        } else {
          this.$notify.textError('Sugestão não encontrada');
        }*/
      } else {
        this.currentSuggestionIndex = -1;
        this.currentResultIndex = -1;

        //foundOils já vem filtrado pelo lote
        loop1:
        for (let i = 0; i < this.foundOils.length; i++) {
          let b = this.foundOils[i];

          if (b.results && b.results.length) {
            for (let j = 0; j < b.results.length; j++) {
              let r = b.results[j];

              if (r.location && r.location.id === location.id) {
                this.currentSuggestionIndex = i;
                this.currentResultIndex = j;
                break loop1;
              }
            }
          }
        }

        if (this.currentSuggestion && this.currentResult) {
          return this.addItemFromSingleSuggestion();
        } else {
          this.$notify.textError('Esse endereço não está entre os endereços das sugestões encontradas');
        }
      }

      return false;
    },

    addItemFromSingleSuggestion() {
      if (this.currentResult.quantity > 0 && (
        !this.currentSuggestion.oil.alocatedQuantity || this.currentSuggestion.oil.alocatedQuantity < this.currentSuggestion.oil.quantity
      )) {
        this.isBeepItemFromFoundOils = true;
        this.saveMovement();
        this.$notify.success('Endereço encontrado');
        return true;
      } else {
        this.$notify.textError('Essa sugestão já foi completamente alocada');
      }

      return false;
    },

    removeFinishedSuggestions() {
      let oilId = this.currentSuggestion.oil.id;
      let oilIndex = this.suggestions.findIndex(s => s.oil.id == oilId);

      if (oilIndex > -1) {
        if (this.suggestions[oilIndex].oil.alocatedQuantity && this.suggestions[oilIndex].oil.alocatedQuantity >= this.suggestions[oilIndex].oil.quantity) {
          this.suggestions.splice(oilIndex, 1);
        } else {
          let locationId = this.currentResult.location.id;

          if (this.suggestions[oilIndex].results) {
            let resultIndex = this.suggestions[oilIndex].results.findIndex(r => r.location && r.location.id == locationId);

            if (resultIndex > -1 && this.suggestions[oilIndex].results[resultIndex].quantity <= 0) {
              this.suggestions[oilIndex].results.splice(resultIndex, 1);
            }
          }
        }

        let suggestions = this.suggestions;
        this.suggestions = [];
        this.suggestions = suggestions;
      }
    }
  }
}
</script>
